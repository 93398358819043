import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'web-site-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss', './mq-service.component.scss'],
})
export class ServiceComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  terSec = [
    {
      img: './../../../assets/services/service-main.svg',
      head: 'OUR SERVICES',
      text: 'GL Terminal offers a wide range of services that make it easy for customers to achieve their business goals, all driven by technology and innovation.',
    },
  ];

  worldClass = [
    {
      img: './../../../assets/mainpage/concretedYard1.svg',
      heading: 'Laden and Empty Container Storage',
      list: [
        'Three hectare yard (with expansion plans on owned surrounding land)',
        'Dry and reefer laden storage',
        '24/7 operational capabilities',
        '180,000 TEU capacity per annum',
        'Empty Storage 5,000 TEUs',
        'Laden Storage 1,000 TEUs',
        'Container Repair Productivity 100 TEUs/Day',
        'Two Side Loaders: 8 tonne capacity /7 stack',
        'Two Reach Stackers: 40 tonne capacity /4 stack',
        'Access to additional equipment as needed',
      ],
      side: 'left',
      id: 'laden',
    },
    {
      img: './../../../assets/mainpage/shades-loads.svg',
      heading: 'Depot Operations',
      list: [
        'Complete range of depot services',
        'Digital process',
        'Implemented ISO9001, 14001and 45001 in day to day operation',
        'Web self service for tax printout, eir, container tracking',
        'Segregation yard by container grading',
        '24Hrs operating service both operational and administration',
        'Container number booking ',
        "Container upgrade as per customer's request",
        'Demurrage detention and repair collection',
      ],
      side: 'right',
      id: 'operation',
    },
    {
      img: './../../../assets/mainpage/stopGate1.svg',
      heading: 'DEPOT OVERVIEW',
      list: [
        '3 Hectare Yard',
        '180,000 TEU throughput PA',
        'Secure site (GLT  partner owns  surrounding 60 ha.)',
        'Two side loaders: 8 tonne capacity / 7  stack',
        'Two reach stackers: 40 tonne capacity / 4  stack',
      ],
      side: 'left',
      id: 'overview',
    },
  ];

  ourSer = [
    {
      img: './../../../assets/onestop.svg',
      head: 'One Stop Gate In',
      text: 'Survey, Washing and Estimate of Repair (EOR)',
    },

    {
      img: './../../../assets/customerservice.svg',
      head: 'Customer service',
      text: 'Check and Validation, Administration, Repair Charge Collection, Demurrage/Detention Collection',
    },

    {
      img: './../../../assets/yardmanagment.svg',
      head: 'Yard Management System',
      text: 'FIFO Concept, Block Arrangement, Traffic Management',
    },

    {
      img: './../../../assets/mainpage/mainatance.svg',
      head: 'Maintenance and Repair',
      text: 'Perform Repair based on IICL standard, Container Upgrade, Refurbishment',
    },

    {
      img: './../../../assets/mainpage/qualitycontrol.svg',
      head: 'Quality Control',
      text: 'Quality Repair based on IICL standard, Recheck whole container condition',
    },

    {
      img: './../../../assets/mainpage/garmenthanger.svg',
      head: 'Garment ON Hanger (GoH)',
      text: 'Fabrication and or Installation only, Single/Double Bar, Standard/Heavy Duty based on Customer request',
    },

    {
      img: './../../../assets/mainpage/referservice.svg',
      head: 'Reefer Services',
      text: 'Pre-Trip Inspection (PTI), Pre-Cooling, Engine Repair, Trouble Shooting and Monitoring',
    },
  ];

  saLoc = [
    {
      title: 'Strategic Location',
      points: [
        { point: '10Km from Tg. Priok Jakarta port' },
        { point: 'Adjacent to Highway TOL Entrance and Exit' },
        { point: 'Highway TOL and Arterial road access from and to the port' },
        { point: 'Smooth traffic flow in local area' },
      ],
    },
    {
      title: 'IT',
      points: [
        { point: 'Robust in-house container Depot Management System' },
        { point: 'QR Code implementation for loading and unloading card' },
        {
          point:
            'Ontime EDI reporting tailor made based on customer request -Codeco, Coparn, Coreor, EDI',
        },
      ],
    },

    {
      title: 'Repair',
      points: [
        { point: 'Real time web dashboard –in-development' },
        { point: 'Android yard system – in-development' },
        { point: 'Barrier Gate Automation – in-development' },
      ],
    },

    {
      title: 'Facilities',
      points: [
        { point: 'Own yard (owned by GLT shareholder)' },
        { point: 'Full concrete yard of three hectares' },
        { point: 'Significant land available for expansion' },
        { point: 'Own container handling equipment' },
        { point: 'Available for empty and laden container stacking' },
        { point: 'Residential-free area' },
        { point: 'Flood free environment' },
      ],
    },

    {
      title: 'People & Resources',
      points: [
        { point: 'International certification IICL, Reefer, Welding' },
        { point: 'Comply with government regulations on manpower' },
        { point: 'Internal and external training program for all employees' },
      ],
    },

    {
      title: 'Depot Operation',
      points: [
        { point: 'Complete range of depot services' },
        { point: 'Digital process' },
        { point: 'Implemented ISO9001,14001and 45001 in day to day operation' },
        { point: 'Web self service for tax printout, eir, container tracking' },
        { point: 'Segregation yard by container grading' },
        {
          point: '24Hrs operating service both operational and administration',
        },
        { point: 'Container number booking' },
        { point: "Container upgrade as per customer's request" },
        { point: 'Demurrage detention and repair collection' },
      ],
    },
  ];
  changeRoute(path: string) {
    this.router.navigate([path]);
    window.scrollTo(0, 0);
  }
}
