import { Component, OnInit } from '@angular/core';
import { GltServiceService } from '../../service/glt-service.service';
import * as fileSaver from 'file-saver';
@Component({
  selector: 'web-site-download-tax',
  templateUrl: './download-tax.component.html',
  styleUrls: ['./download-tax.component.scss'],
})
export class DownloadTaxComponent implements OnInit {
  constructor(private gltService: GltServiceService) {}

  invoiceNumber: any;
  taxNumber: any;
  downloadList = <any>[];
  ngOnInit(): void {}

  download() {
    let data = {
      invoiceNumber: this.invoiceNumber,
      taxNumber: this.taxNumber,
    };
    this.gltService.donwloadTax(data).subscribe(
      (res: any) => {
        console.log(res.data);
        if (res.data) {
          this.downloadList = res.data;
        }
        // const link = document.createElement('a');
        // link.setAttribute('target', '_blank');
        // link.setAttribute('href', `${res.data}`);
        // link.setAttribute('download', `city.xlsx`);
        // document.body.appendChild(link);
        // link.click();
        // link.remove();
      },
      (err) => {
        console.log(err);
        this.gltService.openSnack(`${err.error.message}`, 'Ok');
      }
    );
  }

  downloadImag(imgData: any) {
    let filename = imgData.split('/').at(-1);
    this.gltService.downloadFile(imgData).subscribe(
      (response: any) => {
        let blob: any = new Blob([response], {
          type: 'text/json; charset=utf-8',
        });
        const url = window.URL.createObjectURL(blob);
        fileSaver.saveAs(
          blob,
          filename + '.' + response.type.split('/').at(-1)
        );
      },
      (error: any) => console.log('Error downloading the file')
    );
  }
}
