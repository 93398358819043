import { NewsListComponent } from './component/news-list/news-list.component';
import { CarouselMainComponent } from './component/shared/carousel-main/carousel-main.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from './component/home/main-page/main-page.component';
import { QhseCertificateComponent } from './component/qhse-certificate/qhse-certificate.component';
import { ServiceComponent } from './component/service/service.component';
import { ContactUsComponent } from './component/shared/contact-us/contact-us.component';
import { AboutUsComponent } from './component/static/about-us/about-us.component';
import { TechnologyInnovationComponent } from './component/technology-innovation/technology-innovation.component';
import { NewsDetailsComponent } from './component/news-details/news-details.component';
import { DownloadTaxComponent } from './component/download-tax/download-tax.component';

const routes: Routes = [
  { path: '', component: MainPageComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'qhse-certificate', component: QhseCertificateComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'technology-innovation', component: TechnologyInnovationComponent },
  { path: 'service', component: ServiceComponent },
  { path: 'carousel', component: CarouselMainComponent },
  { path: 'news', component: NewsListComponent },
  { path: 'glterminal-press-release/:id', component: NewsDetailsComponent },
  { path: 'download-tax', component: DownloadTaxComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
