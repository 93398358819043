<section class="technology-bg-section">
  <div class="topnavbar">
    <web-site-top-navbar></web-site-top-navbar>
  </div>
  <div class="f-sec">
    <div class="ino">
      <div class="heading">
        Technology & <br />
        Innovation
      </div>
      <div class="para">
        GL Terminal utilizes the latest technology and industry innovations to
        make the customer experience as seamless as possible. We will continue
        to invest and develop in this area.
      </div>
    </div>
    <div class="i-img">
      <img
        class="tec-inno"
        src="./../../../assets/TechAndInnov.png"
        alt="TechAndInnov"
      />
    </div>
  </div>
  <div>
    <div class="main" id="technology">
      <div class="second">
        <div class="s-img">
          <img
            src="./../../../assets/qrScan.png"
            alt="qrScan"
            class="qr-scan"
          />
        </div>
        <div class="tech">
          <div class="box">
            <div class="technology-heading">
              {{ "TECHNOLOGY" | titlecase }}
            </div>
            <div class="para1">
              As a newcomer to the ICD market GL Terminal is able to take
              advantage of the latest technology that makes it easier for us to
              manage our operations and for customers to do business with us.
              <div class="para2">Our Technology Includes:</div>
              <div class="para1">
                <li>Robust in-house container Depot Management System</li>
                <li>QR Code implementation for loading and unloading card</li>
                <li>
                  Ontime EDI reporting tailor made based on customer request
                  -Codeco, Coparn, Coreor, EDI Repair
                </li>
                <li>Frictionless Payments and Operations</li>
                <li>
                  Depot transactions via Indonesia’s leading payment gateways
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="third" id="innovation">
        <div class="ti">
          <div class="text-section">
            <div class="t-in">{{ "INNOVATION" | titlecase }}</div>
            <div class="para1">
              Our philosophy is to make it easy to do business with GL Terminal
              and provide value to our customers in new and innovative ways.
            </div>
            <div class="para1">
              As part of this innovation we have partnered with logol, one of
              Indonesia's most exciting logistics tech start ups who are
              tackling real problems in the Indonesian supply chain.
            </div>
            <div class="qn">
              How do customers benefit from GL Terminal’s partnership with
              logol?
            </div>
          </div>
          <div class="img-section">
            <img
              class="digital-logistics"
              src="./../../../assets/digitalLogistics.png"
              alt="digitalLogistics"
            />
          </div>
        </div>
        <div class="images">
          <div class="f-box">
            <div class="e-img">
              <img
                src="./../../../assets/electronicGatePass.png"
                width="40px"
              />
            </div>
            <div class="e-text">Electronic gate-pass</div>
            <div class="e-para">
              <li>Reduces manual process</li>
              <li>Increases efficiency and accuracy</li>
              <li>Downstream productivity gains for customers</li>
            </div>
          </div>
          <div class="f-box">
            <div class="e-img">
              <img src="./../../../assets/eletronicPayment.png" width="40px" />
            </div>
            <div class="e-text">Electronic payment of gate in/out fees</div>
            <div class="e-para">
              <!-- <li>Reduces manual process</li>
                        <li> Increases efficiency and accuracy</li>
                        <li>Downstream productivity gains for customers</li> -->
              <li>Reduces cashier transactions</li>
              <li>
                Audit trail to prevent “seepage” and maintain compliance
                standards
              </li>
            </div>
          </div>
          <div class="f-box">
            <div class="e-img">
              <img
                src="./../../../assets/IntegratedTrucking.png"
                width="40px"
              />
            </div>
            <div class="e-text">Integrated trucking marketplace</div>
            <div class="e-para">
              <!-- <li>Reduces manual process</li>
                        <li> Increases efficiency and accuracy</li>
                        <li>Downstream productivity gains for customers</li> -->
              <li>Covered area for open space queue</li>
              <li>
                Physical distancing seating arrangement for shipper and
                consignee queuing
              </li>
            </div>
          </div>
        </div>
      </div>

      <div class="fourth">
        <div class="part">
          <div class="otp" id="partner">
            {{ "OUR TECH PARTNERS" | titlecase }}
          </div>
          <div class="chain">
            GL Terminal has partnered with logol, one of Indonesia's most
            exciting logistics tech start ups who are tackling real problems in
            the Indonesian supply chain. Logol are integrated with.
          </div>
        </div>
        <div class="ipc">
          <img src="./../../../assets/ourPartner.svg" alt="" width="500px" />
        </div>
      </div>

      <div class="fifth">
        <div class="ser">
          <img src="./../../../assets/onlineServices.png" alt="" width="115%" />
        </div>
        <div class="our">
          <div class="os">{{ "ONLINE SERVICES" | titlecase }}</div>
          <div class="ul">
            <ul>
              e-Faktur
            </ul>
            <ul>
              e-EIR
            </ul>
            <ul>
              BL/DO Tracking
            </ul>
            <ul>
              Container Tracking
            </ul>
            <ul>
              GLT Dashboard
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="foot">
    <web-site-footer></web-site-footer>
  </div>
</section>
