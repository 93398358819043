import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'web-site-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss', './mq-footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private route: Router) {}

  ngOnInit(): void {}
  social = [
    {
      image: './../../../../assets/Facebook.png',
    },
    {
      image: './../../../../assets/Twitter.png',
    },
    {
      image: './../../../../assets/Instagram.png',
    },
    {
      image: './../../../../assets/linkedinIcon.png',
    },
  ];
  routingPath(PathName: any) {
    console.log('path');

    this.route.navigate([PathName]);
    window.scrollTo(0, 0);
  }
}
