import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'web-site-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss', 'mq-about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  leaderShip = [
    {
      img: './../../../../assets/leadershipOne.png',
      text1: 'pak eka',
      text2: 'Manager',
    },
    {
      img: './../../../../assets/leadershiptwo.png',
      text1: 'Pak Soehono Koenarto',
      text2: 'Accountant',
    },
    {
      img: './../../../../assets/Rectangle 3223.png',
      text1: 'pak eka',
      text2: 'Manager',
    },
  ];
  meetStaff = [
    {
      img: './../../../../assets/staffOne.png',
      name: 'EDGARS KRONBERGS',
      position: 'Staff',
    },
    {
      img: './../../../../assets/staffTwo.png',
      name: 'EDGARS KRONBERGS',
      position: 'Staff',
    },
    {
      img: './../../../../assets/staffThree.png',
      name: 'EDGARS KRONBERGS',
      position: 'Staff',
    },
    {
      img: './../../../../assets/staffFour.png',
      name: 'EDGARS KRONBERGS',
      position: 'Staff',
    },
  ];
}
