import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'web-site-carousel-main',
  templateUrl: './carousel-main.component.html',
  styleUrls: [
    './carousel-main.component.scss',
    './mq-carousel-main.component.scss',
  ],
})
export class CarouselMainComponent implements OnInit, AfterViewInit {
  constructor() {}
  @ViewChildren('mySlides')
  mySlide!: QueryList<CarouselMainComponent>;
  @ViewChildren('dot')
  dot!: QueryList<CarouselMainComponent>;

  slideIndex = 1;
  ngOnInit(): void {}
  ngAfterViewInit() {
    // this.autoShowSlides();
    this.showSlides(this.slideIndex);
    // setInterval(this.showSlides(),2000)
  }

  scrollList = [
    {
      heading: 'Introducing Jakarta’s World-Class Inland Container Depot',
      subText: `Meet GL Terminal, a new logistics  leader in Indonesia`,
      buttonValue: 'Contact Us',
      img: './../../../../assets/carousel/new-docs-carousel.svg',
    },
    {
      heading: 'Introducing Jakarta’s World-Class Inland Container Depot',
      subText: 'Meet GL Terminal, a new logistics leader in Indonesia',
      buttonValue: 'Contact Us',
      img: './../../../../assets/carousel/docs-stack.svg',
    },
    {
      heading: 'Introducing Jakarta’s World-Class Inland Container Depot',
      subText: 'Meet GL Terminal, a new logistics leader in Indonesia',
      buttonValue: 'Contact Us',
      img: './../../../../assets/carousel/docs-carrier.svg',
    },
    {
      heading: 'Introducing Jakarta’s World-Class Inland Container Depot',
      subText: 'Meet GL Terminal, a new logistics leader in Indonesia',
      buttonValue: 'Contact Us',
      img: './../../../../assets/carousel/docs-arrangement.svg',
    },
  ];

  plusSlides(n: any) {
    this.showSlides((this.slideIndex += n));
  }

  currentSlide(n: any) {
    this.showSlides((this.slideIndex = n));
  }

  showSlides(n: any) {
    let i;
    let dots: any[] = this.dot.toArray();
    let slides: any[] = this.mySlide.toArray();

    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].nativeElement.style.display = 'none';
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].nativeElement.className = dots[i].nativeElement.className.replace(
        ' active',
        ''
      );
    }
    slides[this.slideIndex - 1].nativeElement.style.display = 'block';
    dots[this.slideIndex - 1].nativeElement.className += ' active';
  }

  autoShowSlides() {
    let i;
    let dots: any[] = this.dot.toArray();
    let slides: any[] = this.mySlide.toArray();
    for (i = 0; i < slides.length; i++) {
      slides[i].nativeElement.style.display = 'none';
    }
    this.slideIndex++;
    if (this.slideIndex > slides.length) {
      this.slideIndex = 1;
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].nativeElement.className.replace(
        ' active',
        ''
      );
    }
    slides[this.slideIndex - 1].nativeElement.style.display = 'block';
    dots[this.slideIndex - 1].nativeElement.className += ' active';
    setInterval(this.autoShowSlides, 2000);
  }
}
