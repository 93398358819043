import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'web-site-technology-innovation',
  templateUrl: './technology-innovation.component.html',
  styleUrls: ['./technology-innovation.component.scss', './mq-technology-innovation.component.scss']
})
export class TechnologyInnovationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
