import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
const material = [MatMenuModule];

@NgModule({
  declarations: [],
  imports: [CommonModule, [...material]],
  exports: [...material],
})
export class MaterialModule {}
