import { ContactusService } from './contactus.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'web-site-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss', 'mq-contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  constructor(
    private contactService: ContactusService,
    private _snackBar: MatSnackBar
  ) {}
  fullName: string = '';
  email: string = '';
  mobile: any;
  description: string = '';
  ngOnInit() {}

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  disabled = false;

  submitForm() {
    this.disabled = true;
    this.contactService
      .submitForm({
        fullName: this.fullName,
        email: this.email,
        mobile: this.mobile,
        description: this.description,
      })
      .subscribe(
        (data) => {
          if (data) {
            this.openSnackBar('Form Successfully Filled!', 'Close');
          } else {
            this.openSnackBar(
              'Please refill the form there was some issue!',
              'Close'
            );
          }
        },
        (err) => {
          this.openSnackBar(
            'Please refill the form there was some issue!',
            'Close'
          );
        },
        () => {
          this.disabled = false;
        }
      );
  }
}
