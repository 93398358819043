import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'web-site-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss', './mq-top-navbar.component.scss'],
})
export class TopNavbarComponent implements OnInit {
  constructor(private route: Router, private _vps: ViewportScroller) {}

  @ViewChild('topnav')
  topNav!: ElementRef;

  @ViewChild('midnav')
  midNav!: ElementRef;

  ngOnInit(): void {}
  routingPath(PathName: any) {
    console.log('path');

    this.route.navigate([PathName]);
  }
  menuDisplay(value: string) {
    if (value === 'menu') {
      if (this.midNav.nativeElement.style.display === 'none') {
        this.midNav.nativeElement.style.display = 'flex';
      } else {
        this.midNav.nativeElement.style.display = 'none';
      }
    }
  }

  dropdownLink(page: string, id: string) {
    this.route.navigate([page]);
    setTimeout(() => {
      this._vps.scrollToAnchor(id);
    }, 100);
  }
}
