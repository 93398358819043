<section class="about-us">
  <div class="topnavbar">
    <web-site-top-navbar></web-site-top-navbar>
  </div>
  <div class="first-sec">
    <div class="f-half">
      <div class="head">{{ "About Us" | titlecase }}</div>
      <div class="text">
        While still a new company, GL Terminal has an experienced team and a
        vision for the future. Find out more about the company, vision, and team
        below.
      </div>
    </div>
    <img src="./../../../../assets/ourStory.svg" width="700px" align="right" />
  </div>
  <div>
    <div class="main" id="information">
      <div class="aboutGl-Term">
        <div class="second-section">
          <div class="heading">
            <div class="sub-head">{{ "About GL Terminal" }}</div>
            <div class="text1">
              <div>
                PT. Good Logistics Terminal (GL Terminal) is a new logistics
                leader in Indonesia. Formed in June 2020 we are unencumbered by
                legacy issues, which means we are able to embrace the latest
                technology and modern management principles to ensure we lead
                the industry in service and innovation.
              </div>
              <div>
                Our experienced team possess combined industry domain knowledge
                of over thirty years and are certified to International
                standards in relation to quality, health, and safety and
                environmental practices.
              </div>
              <div>
                Our strategic location adjacent to the Jakarta Outer Ring Road
                TOL places us less than 10kms from Tg Priok Jakarta Port. This
                location also provides direct access to arterial highways
                allowing us to respond rapidly and efficiently to our customers
                Import / Export and distribution requirements. From our
                experienced drivers to our knowledgeable office staff, GL
                Terminal has the desire and commitment to work hand-in-hand to
                develop transportation solution that is truly customized to your
                needs.
              </div>
              <div>
                In response to increasing demand in Indonesia for world-class
                Inland Container Depot (ICD) facilities, a team of
                Indonesian-based entrepreneurs launched PT. Good Logistics (GL)
                Terminal in late 2020. The facility is based in Jakarta, only
                10km from Jakarta port, and has an annual throughput capacity of
                180,000 TEUs. In addition to state-of-the-art material handling
                equipment (MHE), GL Terminal utilizes technology and digital
                innovation to make the user experience easier and more
                efficient, while ensuring greater visibility into the process
                and higher quality for all users.
              </div>
              <div>
                After many years of doing business in Indonesia they realized
                that there was a need and a demand for ICD facilities in
                Indonesia that utilized best-in-class management and quality and
                would help overcome some of the traditional challenges in the
                local market.
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="glt-leadership">
        <div class="glt-imgSection">
          <div class="hover-section">
            <img src="./../../../../assets/Rectangle 3225.png" width="250" />
            <div class="name-pos">
              <div class="name">PAK EKA</div>
              <div class="designation">Manager</div>
            </div>
            <div class="hover-text">
              <div class="name">PAK EKA</div>
              <div class="designation">Manager</div>
              <div class="description">
                Pak Eka also has a very broad business portfolio, which covers
                food manufacturing, logistics and F&B chains, and more recently
                entered into a partnership with Swiss based DKSH in relation to
                their listed distribution company - Wicaksana. Pak Eka is also
                the local partner for Kuehne and Nagel
              </div>
              <div class="icons">
                <div class="fb">
                  <img
                    src="./../../../../assets/Facebook.png"
                    alt=""
                    width="20"
                  />
                </div>
                <div class="twitter">
                  <img
                    src="./../../../../assets/Twitter.png"
                    alt=""
                    width="20"
                  />
                </div>
                <div class="instagram">
                  <img
                    src="./../../../../assets/Instagram.png"
                    alt=""
                    width="20"
                  />
                </div>
                <div class="linkedin">
                  <img
                    src="./../../../../assets/linkedinIcon.png"
                    alt=""
                    width="20"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="hover-section-ac">
            <img src="./../../../../assets/leadershiptwo.png" width="250" />
            <div class="name-pos-ac">
              <div class="name">PAK SOEHONO KOENARTO</div>
              <div class="designation">Accontant</div>
            </div>
            <div class="hover-text">
              <div class="name">PAK SOEHONO KOENARTO</div>
              <div class="designation">Accountant</div>
              <div class="description">
                Pak Koen's company MSA owns the land that GL Terminal operates
                from and also a significant part of the surrounding area, which
                is predominantly used for logistics warehouses that are
                developed and marketed by MSA.Pak Koen also owns a 3PL company
                (BalRich Logistics) which has a fleet of over 400 box trucks
                servicing major FMCG customers.His family's business portfolio
                extends to sugar exporting and various other interests.
              </div>
              <div class="icons">
                <div class="fb">
                  <img
                    src="./../../../../assets/Facebook.png"
                    alt=""
                    width="20"
                  />
                </div>
                <div class="twitter">
                  <img
                    src="./../../../../assets/Twitter.png"
                    alt=""
                    width="20"
                  />
                </div>
                <div class="instagram">
                  <img
                    src="./../../../../assets/Instagram.png"
                    alt=""
                    width="20"
                  />
                </div>
                <div class="linkedin">
                  <img
                    src="./../../../../assets/linkedinIcon.png"
                    alt=""
                    width="20"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="glt-ceoSection">
          <div class="hover-section-ceo">
            <img src="./../../../../assets/leadershipOne.png" width="250" />
            <div class="name-pos">
              <div class="name">PAUL GOOD</div>
              <div class="designation">CEO-Director</div>
            </div>
            <div class="hover-text">
              <div class="name">PAUL GOOD</div>
              <div class="designation">CEO-Director</div>
              <div class="description">
                Paul Good is an experienced logistics professional and
                entrepreneur, with a career that has included working in
                Australia, Indonesia, Hong Kong, Philippines, India, and
                Singapore. Mr Good has worked on both the operational and
                general management sides of the logistics business, and his
                experience includes a multitude of roles at BSA Logistics,
                Kuehne and Nagel, and Agility.
              </div>
              <div class="icons">
                <div class="fb">
                  <img
                    src="./../../../../assets/Facebook.png"
                    alt=""
                    width="20"
                  />
                </div>
                <div class="twitter">
                  <img
                    src="./../../../../assets/Twitter.png"
                    alt=""
                    width="20"
                  />
                </div>
                <div class="instagram">
                  <img
                    src="./../../../../assets/Instagram.png"
                    alt=""
                    width="20"
                  />
                </div>
                <div class="linkedin">
                  <img
                    src="./../../../../assets/linkedinIcon.png"
                    alt=""
                    width="20"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="glt-descSection">
          <div class="leaderShip-heading">
            {{ "GL TERMINAL LEADERSHIP" | titlecase }}
          </div>
          <div class="leaderShip-subtext">
            Our experienced team possess combined industry domain knowledge of
            over thirty years and are certified to International standards in
            relation to quality, health, and safety and environmental practices.
          </div>
        </div>
      </div> -->
      <!-- <div class="fourth-sec">
        <div class="staff">{{ "MEET OUR STAFF" | titlecase }}</div>
        <div class="images-staff">
          <div class="staff-box" *ngFor="let staff of meetStaff">
            <img [src]="staff.img" />
            <div class="inside-text">
              <div class="text9">{{ staff.name }}</div>
              <div class="text10">{{ staff.position }}</div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="fifth-sec">
        <img class="fifth-sec-img" src="./../../../../assets/ourvision.png" />

        <div class="vision" id="vision">
          <div class="vis">{{ "OUR VISION" | titlecase }}</div>
          <div class="para">
            Our vision is to be Indonesia’s most trusted inland container depot
            and warehouse provider by adopting an uncompromising commitment to
            world’s best practise when it comes to:
          </div>
          <div class="para">
            <li>Service Quality</li>
            <li>Compliance</li>
            <li>Health, Safety & Environmental Sustainability</li>
            <li>Application of cutting edge technology</li>
          </div>
          <div class="vis">{{ "360 DEGREE RESPONSIVENESS" | titlecase }}</div>
          <div class="degree-margin">
            It is our promise to respond and embrace:
          </div>
          <div class="bullets">
            <li>Our customers and their customers</li>
            <li>Our team members well-being and development</li>
            <li>Our vendors, suppliers and partners</li>
            <li>Technological Innovations</li>
          </div>
        </div>
      </div>

      <div class="aboutGl-Term leadership-sec" id="process">
        <div class="second-section">
          <div class="heading">
            <div class="sub-head">{{ "GL Terminal Leadership" }}</div>
            <div class="text1">
              <div>
                GL Terminal has been developed by a capable and experienced team
                led by Paul Good.
              </div>
              <!-- <div>
                Pak Koen's company MSA owns the land that GL Terminal operates
                from and also a significant part of the surrounding area, which
                is predominantly used for logistics warehouses that are
                developed and marketed by MSA. Pak Koen also owns a 3PL company
                (BalRich Logistics) which has a fleet of over 400 box trucks
                servicing major FMCG customers. His family's business portfolio
                extends to sugar exporting and various other interests.
              </div> -->
              <div>
                Paul is an experienced logistics professional and entrepreneur,
                with a career that has included working in Australia, Indonesia,
                Hong Kong, Philippines, India, and Singapore. He has worked on
                both the operational and general management sides of the
                logistics business, and his experience includes a multitude of
                roles at Kuehne and Nagel and Agility.
              </div>
              <div>
                GL Terminal’s knowledgeable management team is led by Nanang
                Faruq, a 20-year industry veteran, who previously held roles at
                BSA Logistics, Multicon, and P.T Indrajaya Swastika.
              </div>
              <div>
                Our experienced team possess combined industry domain knowledge
                of over thirty years and are certified to International
                standards in relation to quality, health, and safety and
                environmental practices.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sixth" id="team">
        <div class="msg">
          <div>
            Everyone who comes into contact with GL Terminal should walk away
            feeling positive and inspired by the experience. Ultimately, it will
            be our customers and stakeholders whom determine our value.
          </div>
        </div>
        <div class="g-img">
          <img class="people-img" src="./../../../../assets/people.svg" />
        </div>
      </div>
    </div>
  </div>

  <div class="foot">
    <web-site-footer></web-site-footer>
  </div>
</section>
