<section class="news-bg-section">
  <div class="topnavbar">
    <web-site-top-navbar></web-site-top-navbar>
  </div>
  <div class="f-sec">
    <div class="ino">
      <div class="heading">
        News and Insights <br />
        <br />
      </div>
      <div class="para">
        News and insights from the ICD, logistics, and shipping worlds
      </div>
    </div>
    <div class="i-img">
      <img
        class="tec-inno"
        src="./../../../assets/news-main.png"
        alt="NewsAndInnov"
      />
    </div>
  </div>
  <div class="news-list">
    <p class="news-head">{{ news.heading | titlecase }}</p>
    <p class="news-subtext">{{ news.subHeading | titlecase }}</p>
    <div class="news-block">
      <ng-container *ngFor="let news of news.listofNews; let n = index">
        <div class="news-box">
          <div class="paper-name">
            <img [src]="news.newsImg" alt="{{ news.date }}" />
          </div>
          <div class="paper-main">
            <img [src]="news.mainImage[0]" alt="{{ news.date }}" />
          </div>
          <div class="paper-heading">
            {{ news.newsHeading }}
          </div>
          <div class="paper-content">
            {{ news.newsContent }}
          </div>
          <div class="date-read">
            <div class="date">{{ news.date }}</div>
            <div class="read-more" (click)="pageChange(news.id)">Read More</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="foot">
    <web-site-footer></web-site-footer>
  </div>
</section>
