import { AfterViewChecked, AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'web-site-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, AfterViewChecked, OnInit{
  constructor() {}
  ngAfterViewChecked() {}
  ngOnInit(){
    this.loader = false;
  }
  loader = true;
  ngAfterViewInit() {
    
  }
  title = 'GL-Terminal';
}
