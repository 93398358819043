import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopNavbarComponent } from './component/shared/top-navbar/top-navbar.component';
import { FooterComponent } from './component/shared/footer/footer.component';
import { MainPageComponent } from './component/home/main-page/main-page.component';
import { AboutUsComponent } from './component/static/about-us/about-us.component';
import { QhseCertificateComponent } from './component/qhse-certificate/qhse-certificate.component';
import { ContactUsComponent } from './component/shared/contact-us/contact-us.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { TechnologyInnovationComponent } from './component/technology-innovation/technology-innovation.component';
import { ServiceComponent } from './component/service/service.component';
import { CarouselMainComponent } from './component/shared/carousel-main/carousel-main.component';
import { MaterialModule } from './style/material/material.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NewsListComponent } from './component/news-list/news-list.component';
import { NewsDetailsComponent } from './component/news-details/news-details.component';
import { DownloadTaxComponent } from './component/download-tax/download-tax.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    AppComponent,
    TopNavbarComponent,
    FooterComponent,
    MainPageComponent,
    AboutUsComponent,
    QhseCertificateComponent,
    ContactUsComponent,
    TechnologyInnovationComponent,
    ServiceComponent,
    CarouselMainComponent,
    NewsListComponent,
    NewsDetailsComponent,
    DownloadTaxComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    MatTabsModule,
    MaterialModule,
    HttpClientModule,
    MatSnackBarModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
