import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'web-site-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss', 'mq-main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}
  facilities = [
    {
      image: '/../../../../assets/mainpage/concretedYard.svg',
      text: 'New Leader In Logistics',
      hoverText:
        'PT. Good Logistics Terminal (GL Terminal) is a new logistics leader in Indonesia and aims to provide a world-class inland container depot (ICD) in Jakarta.',
    },
    {
      image: '/../../../../assets/mainpage/containerHandler.svg',
      text: 'Technology And Innovation Driven',
      hoverText:
        'Formed in 2020 GL Terminal is unencumbered by legacy issues, which means we are able to embrace the latest technology and modern management principles to ensure we lead the industry in service and innovation.',
    },
    {
      image: '/../../../../assets/mainpage/stopGate.svg',
      text: 'Experienced Team',
      hoverText:
        'GL Terminal has been developed by an experienced leadership team     and has a skilled management team in place.',
    },
  ];

  transport = [
    {
      image: './../../../../assets/landtransport.svg',
      hoverImg: './../../../../assets/mainpage/landtransporthover.svg',
      imageB: './../../../../assets/landtransport.svg',
    },
    {
      image: './../../../../assets/airfreight.svg',
      hoverImg: './../../../../assets/mainpage/airfeighthover.svg',
      imageB: './../../../../assets/airfreight.svg',
    },
    {
      image: './../../../../assets/seafreight.svg',
      hoverImg: './../../../../assets/mainpage/seafreighthover.svg',
      imageB: './../../../../assets/seafreight.svg',
    },
    {
      image: './../../../../assets/smartwarehouses.svg',
      hoverImg: './../../../../assets/mainpage/smartwarehover.svg',
      imageB: './../../../../assets/smartwarehouses.svg',
    },
  ];

  services = [
    {
      image: './../../../../assets/onestop.svg',
      heading: 'In ONE STOP GATE IN',
      subText: 'Survey, Washing and Estimate of Repair (EOR)',
    },
    {
      image: './../../../../assets/customerservice.svg',
      heading: 'CUSTOMER SERVICE',
      subText:
        'Check and Validation, Administration, Repair Charge Collection, Demurrage/Detention Collection',
    },
    {
      image: './../../../../assets/yardmanagment.svg',
      heading: 'YARD MANAGEMENT SYSTEM',
      subText: 'FIFO Concept, Block Arrangement, Traffic Management',
    },
  ];

  secondService = [
    {
      image: './../../../../assets/mainpage/mainatance.svg',
      heading: 'MAINTENANCE AND REPAIR',
      subText:
        'Perform Repair based on IICL standard, Container Upgrade, Refurbishment',
    },
    {
      image: './../../../../assets/mainpage/qualitycontrol.svg',
      heading: 'QUALITY CONTROL',
      subText:
        'Quality Repair based on IICL standard, Recheck whole container condition',
    },
    {
      image: './../../../../assets/mainpage/garmenthanger.svg',
      heading: 'GARMENT ON HANGER (GoH)',
      subText:
        'Fabrication and or Installation only, Single/Double Bar, Standard/Heavy Duty based on Customer request',
    },
  ];

  whyList = [
    {
      image: './../../../../assets/mainpage/why-pin.svg',
      heading: 'Strategic Location',
      subText: [
        '10 km from Tg.Priok Jakarta sea port',
        'Close to highway TOL entrance and exit',
        'Highway TOL and arterial road access from and to the port',
        'Traffic jam free',
      ],
    },
    {
      image: './../../../../assets/mainpage/facility.svg',
      heading: 'Facilities',
      subText: [
        'Own yard (owned by GLT share holder)',
        'Full concrete yard 3 hectares',
        'Available land for expansion',
        'Own Container handling equipment',
        'Available for empty and laden container stacking',
        'Residential-free area',
        'Flood-free',
      ],
    },
    {
      image: './../../../../assets/mainpage/manpower.svg',
      heading: 'Manpower',
      subText: [
        'International Certification IICL, Reefer, Welding',
        'Complies with government regulations on manpower',
        'Internal and external training program for all employees',
      ],
    },
  ];
  changeRoute(path: string) {
    this.router.navigate([path]);
    window.scrollTo(0, 0);
  }
}
