<section class="download-ubg">
  <div class="topnavbar">
    <web-site-top-navbar></web-site-top-navbar>
  </div>
  <div class="main-section">
    <div class="form-field">
      <div>
        <mat-form-field class="input-width" appearance="outline">
          <input
            matInput
            placeholder="Enter Invoice Number"
            class="mat-input"
            [(ngModel)]="invoiceNumber"
          />
          <span mat-button matSuffix mat-icon-button>
            <mat-icon>search</mat-icon>
          </span>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="input-width" appearance="outline">
          <input
            matInput
            placeholder="Enter Tax ID Number"
            class="mat-input"
            [(ngModel)]="taxNumber"
          />
          <span mat-button matSuffix mat-icon-button>
            <mat-icon>search</mat-icon>
          </span>
        </mat-form-field>
      </div>
      <div class="download-btn">
        <button class="download" (click)="download()">Download</button>
      </div>
      <div class="list-link" *ngIf="downloadList.length > 0">
        <ul *ngFor="let item of downloadList; let i = index">
          <li>
            <a (click)="downloadImag(item)"> Link {{ i + 1 }} </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
