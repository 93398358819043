<section class="news-bg-section">
  <div class="topnavbar">
    <web-site-top-navbar></web-site-top-navbar>
  </div>
  <div class="main-details-section">
    <div class="detail-news">
      <div class="news-info">
        <div class="published">Published In</div>
        <div class="paper-name-main">
          <img [src]="mainNews.newsImg" alt="news" />
        </div>
        <div class="date-main">{{ mainNews.date }}</div>
      </div>
      <div class="main-news-heading">
        {{ mainNews.newsHeading }}
      </div>
      <div class="main-image-sec">
        <div class="paper-main">
          <ng-container *ngFor="let images of mainNews.mainImage">
            <img [src]="images" alt="{{ mainNews.date }}" />
          </ng-container>
        </div>
      </div>
      <div class="content-main">
        <ng-container *ngFor="let item of mainNews.desc">
          <p class="news-details" [innerHtml]="item"></p>
        </ng-container>
      </div>
    </div>
    <div class="list-news">
      <div class="news-list">
        <p class="latest-news">Latest Media & Press Release</p>
        <div class="scroll-block">
          <div class="news-block">
            <ng-container *ngFor="let news of suggestedList; let n = index">
              <div class="news-box">
                <div class="paper-name">
                  <img [src]="news.newsImg" alt="{{ news.date }}" />
                </div>
                <div class="paper-main">
                  <img [src]="news.mainImage[0]" alt="{{ news.date }}" />
                </div>
                <div class="paper-heading">
                  {{ news.newsHeading }}
                </div>
                <div class="paper-content">
                  {{ news.newsContent }}
                </div>
                <div class="date-read">
                  <div class="date">{{ news.date }}</div>
                  <div class="read-more" (click)="pageChange(news.id)">
                    Read More
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="foot">
    <web-site-footer></web-site-footer>
  </div>
</section>
