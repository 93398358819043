<section class="section-bg">
  <div class="navbar-pos">
    <div class="hidden-nav">
      <div class="small-nav" (click)="menuDisplay('menu')">
        <img
          src="./../../../../assets/loader/menu.svg"
          alt="menu"
          class="menu-img"
        />
      </div>
      <div class="contact-nav" (click)="menuDisplay('contact')">Contact</div>
    </div>

    <div class="topnav" #topnav>
      <div class="question">Have any Questions?</div>
      <div class="mail">
        <img src="./../../../../assets/mail.png" class="top-nav-icon" />
        <div class="info">
          <a href="mailto:info@glterminal.com">info@glterminal.com</a>
        </div>
      </div>
      <div class="add">
        <img src="./../../../../assets/mapicon.png" class="top-nav-icon" />
        <div class="info-address">
          <a
            href="https://goo.gl/maps/eXPHs5HAwgE4DoEp7"
            target="_blank"
            rel="noopener noreferrer"
          >
            Jl. Raya Cakung Cilincing Pal II, <br />
            Cilincing Jakarta Utara 14140 Indonesia
          </a>
        </div>
      </div>
      <div class="no">
        <img src="./../../../../assets/callicon.png" class="top-nav-icon" />
        <div class="info">
          <a href="tel:+62 21 2240 6574">+62 21 2240 6574</a>
        </div>
      </div>
      <div class="icons">
        <!-- <img src="./../../../../assets/Facebook.png" class="social-icon" />
        <img src="./../../../../assets/Twitter.png" class="social-icon" />
        <img src="./../../../../assets/Instagram.png" class="social-icon" /> -->
        <a
          href="https://www.linkedin.com/in/gl-terminal-4470211b9/"
          target="_blank"
          rel="noopener noreferrer"
          ><img src="./../../../../assets/linkedinIcon.png" class="social-icon"
        /></a>
      </div>
    </div>

    <div class="mid-nav" #midnav>
      <div class="image-logo">
        <img
          src="./../../../../assets/glWhitelogo.svg"
          class="glLogo"
          [routerLink]="['/']"
        />
      </div>
      <div class="navbar">
        <div
          class="navbar-links dropdown"
          [routerLink]="['/service']"
          routerLinkActive="router-link-active"
        >
          Services
          <div class="dropdown-content">
            <p class="link-hover" (click)="dropdownLink('/service', 'laden')">
              Laden and Empty Container Storage
            </p>
            <p
              class="link-hover"
              (click)="dropdownLink('/service', 'operation')"
            >
              Depot Operations
            </p>
            <p
              class="link-hover"
              (click)="dropdownLink('/service', 'overview')"
            >
              Depot Overview
            </p>
          </div>
        </div>
        <div
          class="navbar-links dropdown"
          [routerLink]="['/technology-innovation']"
          routerLinkActive="router-link-active"
        >
          Technology & Innovation
          <div class="dropdown-content">
            <p
              class="link-hover"
              (click)="dropdownLink('/technology-innovation', 'technology')"
            >
              Technology
            </p>
            <p
              class="link-hover"
              (click)="dropdownLink('/technology-innovation', 'innovation')"
            >
              Innovation
            </p>
            <p
              class="link-hover"
              (click)="dropdownLink('/technology-innovation', 'partner')"
            >
              Tech Partners
            </p>
          </div>
        </div>
        <div
          class="navbar-links"
          [routerLink]="['/qhse-certificate']"
          routerLinkActive="router-link-active"
        >
          QHSE & Certification
        </div>
        <div
          class="navbar-links"
          [routerLink]="['/news']"
          routerLinkActive="router-link-active"
        >
          News
        </div>
        <div
          class="navbar-links dropdown"
          [routerLink]="['/about-us']"
          routerLinkActive="router-link-active"
        >
          About Us
          <div class="dropdown-content">
            <p
              class="link-hover"
              (click)="dropdownLink('/about-us', 'information')"
            >
              Company Information
            </p>

            <p
              class="link-hover"
              (click)="dropdownLink('/about-us', 'process')"
            >
              GL Terminal Leadership
            </p>
          </div>
        </div>
        <!-- <div class="navbar-links">Online Services</div> -->
        <div
          class="navbar-links"
          [routerLink]="['/contact-us']"
          routerLinkActive="router-link-active"
        >
          Contact Us
        </div>

        <!-- menusub options -->
        <!-- <mat-menu #news="matMenu">
          <button mat-menu-item>Media</button>
          <button mat-menu-item>Other</button>
        </mat-menu>
        <mat-menu #onlineService="matMenu">
          <button mat-menu-item>e-Faktur</button>
          <button mat-menu-item>e-EIR</button>
          <button mat-menu-item>Container Tracking</button>
        </mat-menu> -->
      </div>
    </div>
  </div>
</section>
