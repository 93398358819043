<section class="services">
  <div class="topnavbar">
    <web-site-top-navbar></web-site-top-navbar>
  </div>
  <div class="first-sec" *ngFor="let ts of terSec">
    <div class="left">
      <div class="head">{{ ts.head | titlecase }}</div>
      <div class="sub-head">{{ ts.text }}</div>
    </div>
    <div>
      <img src="{{ ts.img }}" alt="alt" class="container-img" />
    </div>
  </div>
  <div>
    <div class="main">
      <div class="second-sec">
        <div class="depot">
          {{ "World-Class Inland Container Depot" }}
        </div>
        <div class="fetures-main">
          <ng-container *ngFor="let item of worldClass; let i = index">
            <div
              class="flex-row-sec"
              [ngClass]="{ 'right-direction': item.side === 'right' }"
              [attr.id]="item.id"
            >
              <div class="img-sec-features">
                <img [src]="item.img" [alt]="item.heading" />
              </div>
              <div class="heading-list-sec">
                <div class="feature-head">
                  {{ item.heading | titlecase }}
                </div>
                <div class="list-feature">
                  <li *ngFor="let listitem of item.list">
                    {{ listitem }}
                  </li>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="fifth-sec" id="operations">
        <div class="facility-head">{{ "FACILITIES AT DEPOT" | titlecase }}</div>
        <div class="images">
          <div class="verticle">
            <div class="box verticle-box">
              <div class="e-box">
                <img
                  src="./../../../assets/services/bus.svg"
                  alt="bus"
                  width="40px"
                  class="e-box"
                />
              </div>
              <div class="ent">
                One Stop Gate In – roof survey, washing and estimate of repair
                (EOR)
              </div>
              <div class="bullets">
                <li>Capacity 1500 TEUs per day</li>
                <li>Three line import gate.</li>
                <li>Single line export gate</li>
                <li>Industries cleaning capability</li>
                <ol>
                  <li>Freshwater cleaning</li>
                  <li>Detergent cleaning</li>
                  <li>Chemical cleaning</li>
                </ol>
                <li>Separate export lane</li>
                <li>Container survey bridge</li>
                <li>Separate entrance and exit</li>
                <li>Surrounded by security fencing</li>
                <li>24 hr security and CCTV monitoring</li>
                <li>Located in an industrrial area</li>
              </div>
            </div>
          </div>
          <div class="horizontal">
            <div class="horizontal-first">
              <div class="box">
                <div class="e-box">
                  <img
                    src="./../../../assets/services/stoper.svg"
                    alt="stopper"
                    width="40px"
                    class="e-box"
                  />
                </div>
                <div class="ent">Wide Entrance</div>
                <div class="bullets">
                  <li>Wide entrance access for container trucks</li>
                  <li>
                    Separate entrance and exit for smooth traffic flow inside
                    depot
                  </li>
                  <li>
                    Dedicated area for visitor’s car and motorcycle parking
                  </li>
                  <li>Available public Moslem prayer room and toilet</li>
                  <li>Assembly point</li>
                </div>
              </div>
              <div class="box">
                <div class="e-box">
                  <img
                    src="./../../../assets/services/hook.svg"
                    alt="hook"
                    width="40px"
                    class="e-box"
                  />
                </div>
                <div class="ent">Heavy equipment</div>
                <div class="bullets">
                  <li>Own heavy equipment.</li>
                  <li>
                    Two side loader eight tonne capacity, seven stacks; two
                    reach stacker 40 tonne capacity.
                  </li>
                  <li>Number of units scalable based on volume.</li>
                  <li>
                    24 hrs workshop team on standby to ensure smooth operations
                  </li>
                </div>
              </div>
              <div class="box">
                <div class="e-box">
                  <img
                    src="./../../../assets/services/headphone.svg"
                    alt="customer"
                    width="40px"
                    class="e-box"
                  />
                </div>
                <div class="ent">Customer Service</div>
                <div class="bullets">
                  <li>Covered area for open space queue</li>
                  <li>
                    Physical distancing seating arrangement for shipper and
                    consignee queuing.
                  </li>
                  <li>24 hrs service</li>
                  <li>Free WIFI access and mobile phone charging points</li>
                </div>
              </div>
            </div>
            <div class="horizontal-second">
              <div class="box">
                <div class="e-box">
                  <img
                    src="./../../../assets/services/social-distance.svg"
                    alt="social distancing"
                    width="40px"
                    class="e-box"
                  />
                </div>
                <div class="ent">Physical Distancing</div>
                <div class="bullets">
                  <li>Seating arrangement for shipper and consignee queuing</li>
                  <li>24 hrs service</li>
                  <li>Free WIFI access and mobile phone charging points.</li>
                </div>
              </div>
              <div class="box box-second">
                <div class="e-box">
                  <img
                    src="./../../../assets/services/yards.svg"
                    alt="bus"
                    width="40px"
                    class="e-box"
                  />
                </div>
                <div class="ent">Square Yard</div>
                <div class="bullets">
                  <li>Square Shape land for standard depot operations</li>
                  <li>Full Concrete Yard</li>
                  <li>
                    Lights installed on all sides of yard for night operation
                  </li>
                  <li>Yard available: three hectares and expandable</li>
                  <li>Available for empty and laden container stacking</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="seventh">
        <div class="Servicesoffer">
          <div class="Services-heading">
            {{ "Services and capabilities" | titlecase }}
          </div>
          <div class="all-Servicesoffer">
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/laden-storage.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "Laden Container Storage" | titlecase }}
                </div>
                <div class="onestop-subtext">
                  Concrete square yard, fully secure
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/empty-conatiner.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "Empty Container Storage" | titlecase }}
                </div>
                <div class="onestop-subtext">
                  Concrete square yard, fully secure
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/maintainance.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "Maintenance and Repair" | titlecase }}
                </div>
                <div class="onestop-subtext">
                  Perform Repair based on IICL standard, Container Upgrade,
                  Refurbishment
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/side-loader.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "Side Loader and Reach Stackers" | titlecase }}
                </div>
                <div class="onestop-subtext">
                  Own equipment, scalable based on volume
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/customer-service.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "Customer service" | titlecase }}
                </div>
                <div class="onestop-subtext">
                  Check and Validation, Administration, Repair Charge
                  Collection, Demurrage/Detention Collection
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/24-7.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "24/7 Operations" | titlecase }}
                </div>
                <div class="onestop-subtext">
                  Full flexibility, dedicated area
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/yard-management.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "Yard Management System" | titlecase }}
                </div>
                <div class="onestop-subtext">
                  FIFO Concept, Block Arrangement, Traffic Management
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/quality-control.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "Quality Control" | titlecase }}
                </div>
                <div class="onestop-subtext">
                  Quality Repair based on IICL standard, Recheck whole container
                  condition
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/garment-hanger.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "Garment oN Hanger " | titlecase }}(GoH)
                </div>
                <div class="onestop-subtext">
                  Fabrication and or Installation only, Single/Double Bar,
                  Standard/Heavy Duty based on Customer request
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/reefer-service.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "Reefer Services" | titlecase }}
                </div>
                <div class="onestop-subtext">
                  Pre-Trip Inspection (PTI), Pre-Cooling, Engine Repair, Trouble
                  Shooting and Monitoring
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/one-stop.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "ONE STOP GATE IN" | titlecase }}
                </div>
                <div class="onestop-subtext">
                  Survey, Washing and Estimate of Repair (EOR)
                </div>
              </div>
            </div>
            <div class="Servicesoffer-image">
              <div class="onestop-image">
                <img
                  src="./../../../../assets/mainpage/service-offer/wide-entrance.svg"
                  class="gate-img"
                />
              </div>
              <div class="onestop-text">
                <div class="onestop-heading">
                  {{ "Wide Entrance" | titlecase }}
                </div>
                <div class="onestop-subtext">Separate entrance and exit</div>
              </div>
            </div>
          </div>
          <div class="btn">
            <button class="submit-btn" (click)="changeRoute('/service')">
              KNOW MORE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="foot">
    <web-site-footer></web-site-footer>
  </div>
</section>
