import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'web-site-qhse-certificate',
  templateUrl: './qhse-certificate.component.html',
  styleUrls: [
    './qhse-certificate.component.scss',
    'mq-qhse-certificate.component.scss',
  ],
})
export class QhseCertificateComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
