import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GltServiceService {
  constructor(
    public dialog: MatDialog,
    private snackbar: MatSnackBar,
    private http: HttpClient
  ) {}

  openSnack(message: string, action: string) {
    this.snackbar.open(message, action, { duration: 5000 });
  }

  donwloadTax(data: { invoiceNumber: any; taxNumber: any }) {
    return this.http.put(
      environment.baseUrl +
        `/document/download?invoiceNumber=${data.invoiceNumber}&taxNumber=${data.taxNumber}`,
      data
    );
  }

  downloadFile(filename: any): any {
    return this.http.get(filename, {
      responseType: 'blob',
    });
  }
}
