<section class="contact-usbg">
  <div class="topnavbar">
    <web-site-top-navbar></web-site-top-navbar>
  </div>
  <div>
    <div class="main-section">
      <div class="contact">
        <div class="heading">Contact Us</div>
        <div class="mail">
          <img src="./../../../../assets/mailToIcon.png" width="30px" />
          <div class="info">
            <a href="mailto:info@glterminal.com">info@glterminal.com</a>
          </div>
        </div>
        <div class="location">
          <img src="./../../../../assets/location.png" width="30px" />
          <div class="address">
            <a
              href="https://goo.gl/maps/eXPHs5HAwgE4DoEp7"
              target="_blank"
              rel="noopener noreferrer"
              >Jl. Raya Cakung Cilincing Pal II, Cilincing<br />
              Jakarta Utara 14140 Indonesia</a
            >
          </div>
        </div>
        <div class="call">
          <img src="./../../../../assets/callToicon.png" width="30px" />
          <div class="no">
            <a href="tel:+62 21 2240 6574">+62 21 2240 6574</a>
          </div>
        </div>

        <div class="icons">
          <!-- <img src="./../../../../assets/facebookBlue.svg" class="social-img" />
          <img src="./../../../../assets/twitterBule.svg" class="social-img" />
          <img
            src="./../../../../assets/instagramBule.svg"
            class="social-img"
          /> -->

          <a
            href="https://www.linkedin.com/in/gl-terminal-4470211b9/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="./../../../../assets/linkedinBlue.svg"
              class="social-img"
            />
          </a>
        </div>
      </div>

      <div class="logistics-img">
        <img
          src="./../../../../assets/services/giflogistics.gif"
          alt="logistics"
          class="logistics"
        />
      </div>
      <!-- <div class="form-field">
        <div>
          <mat-form-field class="input-width" appearance="outline">
            <mat-label>Full Name</mat-label>
            <input matInput placeholder="Full Name" [(ngModel)]="fullName" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="input-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" [(ngModel)]="email" />
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="input-width" appearance="outline">
            <mat-label>Mobile Number</mat-label>
            <input matInput placeholder="Mobile Number" [(ngModel)]="mobile" />
          </mat-form-field>
        </div>

        <div class="message">
          <mat-form-field class="input-width" appearance="outline">
            <mat-label>Message</mat-label>
            <textarea matInput [(ngModel)]="description"></textarea>
          </mat-form-field>
        </div>

        <div class="btn">
          <button
            class="submit-btn"
            (click)="submitForm()"
            [disabled]="disabled"
          >
            Send Message
          </button>
        </div>
      </div> -->
    </div>
  </div>

  <div class="foot">
    <web-site-footer></web-site-footer>
  </div>
</section>
