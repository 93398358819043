import {
  AfterViewInit,
  Component,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NEWS } from './news-constants';

@Component({
  selector: 'web-site-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss', './mq-news-list.component.scss'],
})
export class NewsListComponent implements OnInit {
  constructor(private router: Router) {}

  news = NEWS;
  ngOnInit(): void {}
  pageChange(id: any) {
    this.router.navigate(['/glterminal-press-release', id]);
    window.scrollTo(0, 0);
  }
}
