export const NEWS = {
  heading: 'News and Insights',
  subHeading: '',
  listofNews: [
    {
      id: 'news-10-jan-2022',
      newsImg: './../../../assets/news/glWhitelogo.png',
      mainImage: ['./../../../assets/news/10jan22/2.jpeg'],
      newsHeading: `GL Terminal Receives Bonded Logistics Center (PLB) License from Jakarta Customs and Excise.`,
      newsContent:
        'New license will allow for the provision of a range of new benefits and increased options for its growing customer base.',
      date: '10th January, 2022',
      desc: [
        `<i>New license will allow for the provision of a range of new benefits and increased options for its growing customer base.</i>`,
        `Jakarta, Indonesia, January 10, 2022: PT Good Logistics Terminal  <a href="https://glterminal.com/" target="_blank">(GL Terminal)</a> recently received a bonded logistics center (PLB) license from the Jakarta Customs Regional Office (Kanwil). The license was awarded after GL Terminal entered into a rigorous and robust due diligence process with the relevant authorities, and displayed its credentials and accreditations as a transparent and compliant organization in order to pass the very high standards required to obtain the PLB license.`,
        `The granting of the license was carried out by the Head of the Jakarta Customs and Excise Office, Rusman Hadi, who commented that, "Before receiving its license, PT Good Logistics Terminal undertook a full review with the assistance of Customs and Excise. The award of the PLB facility license is one of the Indonesian Government's efforts to provide incentives to business actors, as well as a form of effort in national economic recovery (PEN)." Companies that receive the PLB facility license will receive benefits such as suspension of import duties, suspension of taxes, suspension of import licenses, and a period of stockpiling of goods of more than three years. Rusman also stated that "We hope that the use of this facility can be an effort to boost the domestic economy."`,
        `In receiving the PLB license Nanang Faruq, GL Terminal Managing Director said, “We are grateful to the support of the Jakarta Customs and Excise Office for the trust given to GL Terminal and will work to ensure we contribute to the growth of the Indonesian economy. This new license will allow us to offer a range of value-added services to our customers and will increase the visibility that we are able to offer into their supply chains.” `,

        `The new PLB license is another step for GL Terminal in its aim to "Transform terminals". Customers will now be able to benefit from features such as import duty and tax suspension, and the ability to store goods for longer. By having visibility of the customer’s cargo from warehouse to container to the terminal, GL Terminal can foresee and anticipate any disruptions within the supply chain and take action early to ensure cargo moves in a timely and cost-effective way. Excess supply chain costs are often the result of fractures along the route, and the more visibility GL Terminal (and its customers) have, the more effectively we can take evasive action to minimize disruption.  `,
        `Commenting on the award, Paul Good, GL Terminal Founder said, “We are delighted to receive the PLB license and would like to give thanks to the Jakarta Customs Regional Office for making the entire process speedy, transparent, and efficient. We understand that the authorities only approve PLB licenses if they believe the proposal will benefit the Indonesian economy and to aid growth in its core industries, and are pleased that GL Terminal can continue to contribute to this important area.”`,

        `Since its launch in 2020 GL Terminal has focused on providing world-class customer satisfaction with a focus on legal and regulatory compliance, coupled with management quality and assurance, all driven by an experienced management and team and valid international certification. The addition of the PLB license will allow it to add extra products and services to its customers and support their supply chain requirements.`,

        `--------------------------------------------------------------------------------`,
        `About GL Terminal`,
        `PT. Good Logistics Terminal (GL Terminal) is a new logistics leader in Indonesia. Formed in 2020 we are unencumbered by legacy issues, which means we are able to embrace the latest technology and modern management principles to ensure we lead the industry in service and innovation.`,
        `GL Terminal has been developed by a team led by Paul Good. Paul is an experienced logistics professional and entrepreneur, with a career that has included working in Australia, Indonesia, Hong Kong, Philippines, India, and Singapore. He has worked on both the operational and general management sides of the logistics business, and his experience includes a multitude of roles at Kuehne and Nagel and Agility.`,
        `Our experienced team possesses combined industry domain knowledge of over thirty years and is certified to International standards in relation to quality, health, and safety and environmental practices. Our strategic location adjacent to the Jakarta Outer Ring Road TOL places us less than 10kms from Tg Priok Jakarta Port. This location also provides direct access to arterial highways allowing us to respond rapidly and efficiently to our customers’ import/export and distribution requirements.`,
        `For more information please contact Nanang Faruq at +62 21 2240 6574 or nanang@glterminal.com or visit our website at <a href="https://www.glterminal.com/" target="_blank">https://www.glterminal.com/</a>`,
      ],
    },
    {
      id: 'news-21-oct-2021',
      newsImg: './../../../assets/news/glWhitelogo.png',
      mainImage: ['./../../../assets/news/18oct21/1.png'],
      newsHeading: `GL Terminal and Clickargo Announce Strategic Partnership To Provide Digital Logistics Solutions in Indonesia`,
      newsContent:
        'New partnership between the two digitally-focused companies will provide range of services to boost efficiency and drive productivity in line with Indonesian Government’s vision',
      date: '21st October, 2021',
      desc: [
        `<i>New partnership between the two digitally-focused companies will provide range of services to boost efficiency and drive productivity in line with Indonesian Government’s vision</i>`,
        `Jakarta, Indonesia, October 21, 2021: <a href="https://glterminal.com/" target="_blank">GL Terminal</a> and <a href="https://clickargo.com/" target="_blank">PT GUUD Logistics Indonesia (operating under the brand “Clickargo”) </a> have announced the launch of a strategic partnership in Indonesia that will collaborate to provide digital logistics solutions to a range of different stakeholders. The solutions will include full connectivity with customs authorities, ports and terminals, banks and financial institutions, insurance companies, transportation management providers, ocean carriers/airlines, freight forwarders/3PLs and shippers. `,
        `To begin with, the two companies will collaborate to provide truck and container management and e-payment solutions. These services will be aimed at existing and new customers, with co-development of new products and services also planned to help digitization in the local market.`,
        `Commenting on the partnership, Nanang Faruq, GL Terminal Managing Director said, “Our partnership with Clickargo is expected to play an active role in realizing an improved digital supply chain in terms of service container management, which is currently mostly done via manual processes. It will provide convenience for customers by simplifying processes and making them more transparent, accountable and documented. It is not only about making it easier to do business, but it is also aligned with the Indonesian Government program to eliminate extra/hidden costs that contribute to higher logistics costs for all. It is very important for service providers (such as container depots) to play a part in driving efficiencies and to focus on the quality of service.”`,
        `Beny Syamrizal, Product Director Clickargo, added, “Our collaboration with GL Terminal allows us to create new connections and processes within our platform and close existing gaps where many stakeholders operate individually, and not on a common platform for easy access. Our goal is to incorporate all processes and stakeholders on a single logistics platform that integrates all upstream and downstream processes. We are committed to working with partners that will help drive digitization, to provide benefits to all parties. We look forward to a rewarding and successful relationship together.” `,
        `Since its arrival in the Indonesian logistics market GL Terminal has focused on offering its customers the latest cutting edge transport solutions and the partnership with Clickargo is part of its mission to provide mid-mile services with a similar level of technological innovation as to what’s being offered in the last mile. `,
        `GL Terminal’s vision is to be Indonesia's most trusted inland container depot and container freight station by adopting an uncompromising commitment to world-class best practices when it comes to service quality, compliance, HSSE, environmental sustainability, and technology. In addition to the technology focus, GL Terminal has an experienced management and operations team with extensive industry domain knowledge, many of whom are certified to international standards for areas related to ICD and associated operations.`,

        `--------------------------------------------------------------------------------`,
        `About GL Terminal`,
        `PT. Good Logistics Terminal (GL Terminal) is a new logistics leader in Indonesia. Formed in 2020 we are unencumbered by legacy issues, which means we are able to embrace the latest technology and modern management principles to ensure we lead the industry in service and innovation.`,
        `GL Terminal has been developed by a team led by Paul Good. Paul is an experienced logistics professional and entrepreneur, with a career that has included working in Australia, Indonesia, Hong Kong, Philippines, India, and Singapore. He has worked on both the operational and general management sides of the logistics business, and his experience includes a multitude of roles at Kuehne and Nagel and Agility.`,
        `Our experienced team possess combined industry domain knowledge of over thirty years and are certified to International standards in relation to quality, health, and safety and environmental practices. Our strategic location adjacent to the Jakarta Outer Ring Road TOL places us less than 10kms from Tg Priok Jakarta Port. This location also provides direct access to arterial highways allowing us to respond rapidly and efficiently to our customers import / export and distribution requirements.`,
        `For more information please contact Nanang Faruq at +62 21 2240 6574 or nanang@glterminal.com or visit our website at <a href="https://www.glterminal.com/" target="_blank">https://www.glterminal.com/</a>`,
        `About PT GUUD Logistics Indonesia (Operating under the brand “Clickargo”)`,
        `PT GUUD Logistics Indonesia (operating under the brand “Clickargo”) is a subsidiary of GUUD Pte Ltd, headquartered in Singapore.  GUUD, a new digital way to trade globally, is a technology platform and group of businesses under the vCargo Cloud umbrella. The GUUD group references the UN/CEFACT Buy-Ship-Pay model to cover all aspects of trade facilitation, bypassing long-standing barriers to trade, and digitally transforming trade processes so they become simpler, more efficient and more inclusive. `,
        `Led by a strong team of industry and IT professionals, GUUD aspires to help anyone and everyone, especially micro, small and medium enterprises, to become a player in global trade and to export and import from any part of the world.`,
        `Our mission is to Trade for Good, and guided by UN’s sustainable development goals, we pursue what is good for people, businesses and economies, helping improve livelihoods, generating prosperity and creating technological efficiencies and improvements everywhere we go.`,
        `Clickargo integrates all logistics-related processes onto a single platform, allowing traders to apply for permits, plan, book and track shipments simply at any time of the day with full visibility over every transaction. Watch this video to find out more: <a href="https://www.youtube.com/watch?v=yWLTMxxlO0c" target="_blank">Click Here</a>`,
        `For more information please contact Beny Syamrizal at +62 21 2265 5073 or beny@guud.company or visit our website at <a href="https://clickargo.com/" target="_blank">Clickcargo</a>`,

        `<img width="50%" src="./../../../assets/news/18oct21/2.jpg"/>`,
      ],
    },
    {
      id: 'news-9-sep-2021',
      newsImg: './../../../assets/news/glWhitelogo.png',
      mainImage: ['./../../../assets/news/9sep21/2.jpg'],
      newsHeading: `GL Terminal Sets New Standards of Excellence For Inland Container Depots in Indonesia`,
      newsContent:
        'Recent Government review of GL Terminal results in full compliance with requirements and raising of standards in the local market. ',
      date: '9th September, 2021',
      desc: [
        `<i>Recent Government review of GL Terminal results in full compliance with requirements and raising of standards in the local market.</i>`,
        `Jakarta, Indonesia, September 9, 2021: <a href="https://glterminal.com/" target="_blank">GL Terminal</a> was visited in late August for a full evaluation of its operations by a delegation from various Indonesian Government departments. The aim of the visit was the continuation of a series of reviews that are carried out into inland container depots (ICDs) in Indonesia to assess and evaluate operations, and this was the first time that GL Terminal had been assessed.`,
        `The visit was led by Department of Transportation DKI Jakarta and was joined by the Directorate of Traffic and Sea Transportation of the Ministry of Transportation of the Republic of Indonesia, Jabodetabek Transportation Management Agency, Main Port Authority of Tanjung Priok and Indonesia Depot Container Association. From GL Terminal’s side, Nanang Faruq was on hand to provide an overview of the site and answer all questions with the assistance of his team.`,
        `After a comprehensive site and operational review it was assessed and certified that GL Terminal has complied with the company's administrative and technical requirements as required by the Indonesian Government. In addition, the assessment team also said that GL Terminal provided an excellent example and set a new standard for container depots in Indonesia. `,
        `Despite being a relative newcomer to the Indonesia market, GL Terminal was able to meet all the strict requirements of the review and is delighted to be contributing to the continued growth and professionalism of the ICD market in Indonesia. Commenting on the assessment, Nanang Faruq, GL Terminal Managing Director said, “We are delighted to receive this recognition from the relevant authorities as it supports the high standards that we have set ourselves in terms of compliance, quality, and professional standards. These were the aims of GL Terminal when we started last year.”`,
        `Since being setup last year, GL Terminal’s focus has been firmly on legal and regulatory compliance, coupled with management quality and assurance, all driven by an experienced management and team and valid international certification. In terms of customer requirements, this has meant that the service provided has exceeded expectations in many cases and continued customer satisfaction will be a driving point for GL Terminal’s activities.`,

        `--------------------------------------------------------------------------------`,
        `About GL Terminal`,
        `PT. Good Logistics Terminal (GL Terminal) is a new logistics leader in Indonesia. Formed in 2020 we are unencumbered by legacy issues, which means we are able to embrace the latest technology and modern management principles to ensure we lead the industry in service and innovation.`,
        `GL Terminal has been developed by a team led by Paul Good. Paul is an experienced logistics professional and entrepreneur, with a career that has included working in Australia, Indonesia, Hong Kong, Philippines, India, and Singapore. He has worked on both the operational and general management sides of the logistics business, and his experience includes a multitude of roles at Kuehne and Nagel and Agility.`,
        `Our experienced team possess combined industry domain knowledge of over thirty years and are certified to International standards in relation to quality, health, and safety and environmental practices. Our strategic location adjacent to the Jakarta Outer Ring Road TOL places us less than 10kms from Tg Priok Jakarta Port. This location also provides direct access to arterial highways allowing us to respond rapidly and efficiently to our customers import / export and distribution requirements.`,
        `For more information please contact Paul Good at +62 812-8828-2221 or Paul@glterminal.com or visit our website at <a href="https://www.glterminal.com/" target="_blank">https://www.glterminal.com/</a>`,
        `Operations at GL Terminal in Jakarta`,
        `<img width="50%" src="./../../../assets/news/9sep21/1.jpg"/>`,
      ],
    },
    {
      id: 'news-9-aug-2021',
      newsImg: './../../../assets/news/glWhitelogo.png',
      mainImage: ['./../../../assets/news/6aug21/newsbar.png'],
      newsHeading: `GL Terminal and Ezyhaul Announce Strategic Partnership To Provide Range of Container, Storage and Transportation Services in Indonesia`,
      newsContent:
        'New partnership between the two technology-focused companies will provide range of services to allow companies to further grow their capabilities in Indonesia ',
      date: '9th August, 2021',
      desc: [
        `<i>New partnership between the two technology-focused companies will provide range of services to allow companies to further grow their capabilities in Indonesia</i>`,
        `Jakarta, Indonesia, August 9, 2021: <a href="https://glterminal.com/" target="_blank">GL Terminal</a> and <a href="https://www.ezyhaul.com/id/" target="_blank">Ezyhaul</a> have announced the immediate launch of a strategic partnership in Indonesia that will combine the container handling and inland container depot knowledge and expertise of GL Terminal with Eyzhaul’s digital road freight platform that allows customers to make bookings for domestic shorthaul, longhaul and cross border shipments. `,
        `At the start of this important partnership the two companies will collaborate to provide container haulage, empty and full container storage, container handling at ports, and will be implementing e-logistics supply chains based on an existing digital platform. The partnership will aim to provide services to existing and new customers and will co-develop new products and services to overcome some of the challenges that exist in the local market.`,
        `Commenting on the partnership, Nanang Faruq, GL Terminal Managing Director said, “GL Terminal and Ezyhaul have similar visions and strategies that drive our business focus and a belief that technology and utilizing digital processes can lead to a better customer experience. We are delighted to be entering into this partnership that will see benefits for both companies and more importantly, will aid Indonesia’s efficiency when it comes to logistics and supply chains.”`,
        `Trie Maulana A, Managing Director Ezyhaul Technologies Indonesia, added, “Ezyhaul is focused on digitizing the logistics industry and this partnership with GL Terminal will help us on our journey. We are committed to working with partners that will help drive digitization, and with it, benefit to all parties. We look forward to a rewarding and successful relationship.”`,
        `As a relative newcomer to the inland container depot and container handling market in Indonesia, GL Terminal is focused on offering its customers the latest cutting edge transport solutions and the partnership with Ezyhaul is part of its mission to provide mid-mile services with a similar level of technological innovation as to what’s being offered in the last mile. `,
        `GL Terminal’s vision is to be Indonesia's most trusted inland container depot and container freight station by adopting an uncompromising commitment to world-class best practices when it comes to service quality, compliance, HSSE, environmental sustainability, and technology. In addition to the technology focus, GL Terminal has an experienced management and operations team with extensive industry domain knowledge, many of whom are certified to international standards for areas related to ICD and associated operations.`,
        `--------------------------------------------------------------------------------`,
        `About GL Terminal`,
        `PT. Good Logistics Terminal (GL Terminal) is a new logistics leader in Indonesia. Formed in 2020 we are unencumbered by legacy issues, which means we are able to embrace the latest technology and modern management principles to ensure we lead the industry in service and innovation.`,
        `GL Terminal has been developed by a team led by Paul Good. Paul is an experienced logistics professional and entrepreneur, with a career that has included working in Australia, Indonesia, Hong Kong, Philippines, India, and Singapore. He has worked on both the operational and general management sides of the logistics business, and his experience includes a multitude of roles at Kuehne and Nagel and Agility.`,
        `Our experienced team possess combined industry domain knowledge of over thirty years and are certified to International standards in relation to quality, health, and safety and environmental practices. Our strategic location adjacent to the Jakarta Outer Ring Road TOL places us less than 10kms from Tg Priok Jakarta Port. This location also provides direct access to arterial highways allowing us to respond rapidly and efficiently to our customers import / export and distribution requirements.`,
        `For more information please contact Nanang Faruq at +62 21 2240 6574 or nanang@glterminal.com or visit our website at  <a href="https://www.glterminal.com/" target="_blank">https://www.glterminal.com/</a>`,
        `<b><strong>About Ezyhaul</strong></b>`,
        `Ezyhaul is a one of the largest digital freight platforms in South Asia focused on transforming the road freight industry. Operating in India, Indonesia, Malaysia, Philippines, Singapore and Thailand, we connect shippers with pre-qualified and reliable trucking companies. `,
        `Through our platform, customers are able to get better visibility of their shipments and are therefore able to better manage their transport operations. Registered transporters are able to find additional loads for their trucks, improve their vehicle utilization and increase their profitability.`,
        `Ezyhaul’s vision is to transform the world of transportation by building an ecosystem around shippers, trucking companies, and third party service partners. Connected through the Internet of Things and driven by data analytics, we will empower all our partners in the ecosystem.`,
        `Founded by a team of logistics veterans we understand the challenges in the industry. We apply the latest technology to make shipping Ezy and create a hassle free experience for clients and transporters.`,
        `For more information please contact Trie Maulana A at +62 21 2781 2289 or <a href="t.maulana@ezyhaul.com" target="_blank">t.maulana@ezyhaul.com</a>`,
        `<i>Ezyhaul and GL Terminal celebrate signing of strategic partnership</i>`,
        `<img width="50%" src="./../../../assets/news/6aug21/team.jpg"/>`,
      ],
    },
    {
      id: 'news-20-july-2021',
      newsImg: './../../../assets/news/glWhitelogo.png',
      mainImage: ['./../../../assets/news/20july21/images/image2.jpg'],
      newsHeading: `Global Shipping Line Appoints GL Terminal To Provide Container Depot and Trucking Services in Jakarta, Indonesia`,
      newsContent:
        'Leading shipping line known for their high levels of quality and compliance chooses GL Terminal due to its service levels, experienced management, and focus on compliance',
      date: '20th July, 2021',
      desc: [
        `<i>Leading shipping line known for their high levels of quality and compliance chooses <a href="https://glterminal.com/" target="_blank">GL Terminal</a> due to its service levels, experienced management, and focus on compliance</i>`,
        `Jakarta, Indonesia, July 20, 2021: GL Terminal has announced that one of the world’s largest shipping lines has appointed it to provide container depot and trucking services in Jakarta, with the contract starting with immediate effect. The contract was awarded to <a href="https://glterminal.com/" target="_blank">GL Terminal</a> after a competitive tender and reflects the promised service levels, quality, and compliance that were seen as key to a successful bid.`,
        `As a recent newcomer to the inland container depot market in Indonesia, GL Terminal is focused on providing world-class services and solutions to its fast-growing customer base. In addition to state-of-the-art material handling equipment, GL Terminal utilizes technology and digital innovation to make the user experience easier and more efficient, while ensuring greater visibility into the process and higher quality for all users.`,
        `For the new contract GL Terminal will provide a range of services, including empty container handling and storage, container inspection, cleaning and repair, empty truck repositioning, garment on hanger (GOH) fabrication and installation, and reefer pre-trip inspection, monitoring, trouble-shooting and repair. Additional services will also be provided if required.`,
        `The award of the contract followed a lengthy and detailed tender and requirements assessment that included both technical and compliance information and recognition of the importance of the customer’s code of conduct. Key factors in the award included GL Terminal’s focus on legal and regulatory compliance, management quality and assurance, including an experienced management and team and valid international certification, and the ability and qualification to provide the service. Also considered important was the facility setup and location and the use of technology and innovation to overcome some traditional challenges. `,
        `Discussing the award Paul Good, GL Terminal founder said “We are delighted to receive this contract and it is recognition of the hard work and dedication from the whole team and a validation of the strategy that we embarked upon when launching GL Terminal. We look forward to a strategic and mutually beneficial partnership and possible future expansion and further cooperation.”`,
        '-----------------------------------------------------------------',
        `About GL Terminal`,
        `PT. Good Logistics Terminal (GL Terminal) is a new logistics leader in Indonesia. Formed in 2020 we are unencumbered by legacy issues, which means we are able to embrace the latest technology and modern management principles to ensure we lead the industry in service and innovation.`,
        `GL Terminal has been developed by a team led by Paul Good. Paul is an experienced logistics professional and entrepreneur, with a career that has included working in Australia, Indonesia, Hong Kong, Philippines, India, and Singapore. He has worked on both the operational and general management sides of the logistics business, and his experience includes a multitude of roles at Kuehne and Nagel and Agility.`,
        `Our experienced team possess combined industry domain knowledge of over thirty years and are certified to International standards in relation to quality, health, and safety and environmental practices. Our strategic location adjacent to the Jakarta Outer Ring Road TOL places us less than 10kms from Tg Priok Jakarta Port. This location also provides direct access to arterial highways allowing us to respond rapidly and efficiently to our customers import / export and distribution requirements.`,
        `For more information please contact Paul Good at +62 812-8828-2221 or Paul@glterminal.com or visit our website at <a href="https://www.glterminal.com/"> https://www.glterminal.com/</a>`,
      ],
    },
    {
      id: 'gl-terminal-in-the-news',
      newsImg: './../../../assets/news/glWhitelogo.png',
      mainImage: ['./../../../assets/news/19july21/images/image3.png'],
      newsHeading: `GL Terminal opens its gates to 'modernise' Indonesian box logistics`,
      newsContent:
        'The story of GL Terminal has been making news around the world. Below are some of the news sites that have covered us recently. Please feel free to have a look about what is being said about us.',
      date: '20th July, 2021',
      desc: [
        `<h2>GL Terminal in the News</h2>`,
        `The story of GL Terminal has been making news around the world. Below are some of the news sites that have covered us recently. Please feel free to have a look about what is being said about us.`,
        `<h2>The Loadstar</h2>`,
        `<a href="https://theloadstar.com/gl-terminal-opens-its-gates-to-modernise-indonesian-box-logistics/" target="_blank"><h3> GL Terminal opens its gates to 'modernise' Indonesian box logistics</h3> </a>`,
        `<p>by: <a href="https://theloadstar.com/?s=Sam+Whelan" target="_blank">Sam Whelan</a> <br> 09/07/2021</p>`,
        `A new tech-focused container depot has opened in Jakarta, with the firm’s founders set to modernise Indonesia’s inland box logistics.`,
        `GL Terminal is the latest project from logistics veteran Paul Good, who, after heading an Indonesian freight forwarder, spotted a gap in the market to serve Tanjung Priok port with a “world-class” inland container depot (ICD).`,
        `The three hectare site is just 10km from the port, and has an annual handling capacity of 180,000 teu.`,
        `Mr Good said the company’s goal was to “disrupt traditional practices” in the local market, “driving productivity, innovation and compliance levels that are expected in other markets and in demand from shipping companies, shippers, and forwarders”.`,
        `He told The Loadstar his mantra was that whatever technology existed in a developed country could also work in a developing country, and added: “There’s no reason you can’t have a depot in Jakarta with the same quality and systems as Sydney or Singapore.”`,
        `It took Mr Good and his partners a year to get the terminal up and running, and last week secured its first customer – a major shipping line, bringing around 11,000 teu a month into Jakarta, spread across three depots.`,
        `Mr Good says the sector is “at the bottom of the food chain”, in terms of modern equipment and technology, which leaves it open to inefficiencies and corruption.So  the port needs better ICDs, he adds.`,
        `For example, GL Terminal has partnered with start-up Logol, a local firm providing electronic payments and gate passes to port operators.`,
        `“In Indonesia, when you want to drop a container down to the port, you’ve need a paper gate pass, pay fees in cash and get a receipt,” he explained. “So Logol has digitised that process and taken a lot of the pain out of it. Naturally it made sense for us as a depot to partner with them and speed up activities.”`,
        `While Indonesia has seen its fair share of venture capital pouring into logistics start-ups, Mr Good said many were “coming up with solutions to problems that don’t exist,” such as “AirBnB for warehousing” or “Uber for trucking”.`,
        `He added: “They’ve raised all this money, but now they don’t know what to do with it, because their solutions are, to a large degree, just ‘thought bubbles’.” Furthermore, he said, most of the successful tech focus so far had been digitising last-mile logistics, leaving plenty of opportunity further up the container chain.`,
        `“Last-mile is crowded, and it’s been quite prosperous, but when you come back up the chain, then the closer to the wharf the more premium it becomes.`,
        `“What no one is really looking at is mid-mile. And that’s another reason why I’ve started GL Terminal, because I believe there’s space for innovation and to complement the tremendous work done on the last-mile,” said Mr Good.`,
        `<img width="50%" src="./../../../assets/news/19july21/images/image5.jpg"/>`,
        `Hellenic Shipping News`,
        `<a href="https://www.hellenicshippingnews.com/experienced-management-team-launch-gl-terminal-an-innovative-and-digitally-focused-inland-container-depot-to-serve-jakarta-and-surrounding-areas/" target="_blank">Experienced Management Team Launch GL Terminal, An Innovative and Digitally Focused Inland Container Depot to Serve Jakarta and Surrounding Areas</a>`,
        `<img width="50%" src="./../../../assets/news/19july21/images/image4.png"/>`,
        `American Journal of Transportation`,
        `<a href="https://ajot.com/news/experienced-management-team-launch-gl-terminal-an-innovative-and-digitally-focused-inland-container-depot-to-serve-jakarta-and-surrounding-areas" target="_blank">Experienced Management Team Launch GL Terminal, An Innovative and Digitally Focused Inland Container Depot to Serve Jakarta and Surrounding Areas</a>`,
        `<img width="50%" src="./../../../assets/news/19july21/images/image7.jpg"/>`,
        `Logistik News Indonesia`,
        `<a href="https://www.logistiknews.id/2021/06/02/gl-terminal-garap-layanan-icd-logistik-berstandar-global/" target="_blank">GL Terminal garap Layanan ICD & Logistik Berstandar Global</a>`,
        ,
        `<img width="50%" src="./../../../assets/news/19july21/images/image6.png"/>`,
        `Translogtoday`,
        `<a href="http://translogtoday.com/2021/06/02/inland-container-depot-gl-terminal-ready-to-serve-jakarta-and-surrounding-areas" target="_blank">Inland Container Depot GL Terminal Ready to Serve Jakarta and Surrounding Areas</a>`,
        `<img width="50%" src="./../../../assets/news/19july21/images/image2.png"/>`,
        `<a href="https://www.indonesiawaterportal.com/news/gl-terminal-opens-its-gates-to-modernise-indonesian-box-logistics.html" target="_blank">GL Terminal opens its gates to 'modernise' Indonesian box logistics</a>`,
        `<img width="50%" src="./../../../assets/news/19july21/images/image1.png"/>`,
        `Logistics Manager`,
        `<a href="https://www.logistics-manager.com/experienced-management-team-launch-gl-terminal-in-indonesia/" target="_blank">Experienced Management Team Launch GL Terminal in Indonesia</a>`,
      ],
    },
    {
      id: 'news-13-june-2021',
      newsImg: './../../../assets/news/glWhitelogo.png',
      mainImage: ['./../../../assets/news/13thjunenews.png'],
      newsHeading:
        '10 Things To Look For When Using Inland Container Depots in Indonesia',
      newsContent:
        'With global trade forecast to grow strongly in 2021 according to UNCTAD, the role and importance of inland container depots (ICDs) for both exporters and importers cannot be underestimated. Companies that work with well-organized and well-managed ICDs will benefit from the ability to manage their laden and empty containers and utilize a range of other services.',
      date: '13th June, 2021',
      desc: [
        `With global trade forecast to grow strongly in 2021 according to UNCTAD, the role and importance of inland container depots (ICDs) for both exporters and importers cannot be underestimated. Companies that work with well-organized and well-managed ICDs will benefit from the ability to manage their laden and empty containers and utilize a range of other services.`,
        `However, companies need to keep in mind that not all ICDs will be able to offer the level and range of services that they are looking for.  To help companies make the best decision with regard to ICD choice we have prepared this guide of 10 things to know about using ICDs in Indonesia.`,
        `10 things to look for when using ICDs in Indonesia::`,
        `<ol>
        <li>Ensure the ICD has a <strong> Depot licence</strong> <a href="https://peraturan.go.id/peraturan/view.html?id=11e7392403cd46029a3e313130373330" target="_blank">(SIUPDPK)</a> and is a registered company</li>
        <li>Check the ICD is <b>Quality compliant</b> with regard to  ISO and other certifications</li>
        <li>Does the ICD have <b>adequate insurance?</b></li>
        <li>Is the Operations team <b>experienced and qualified?</b></li>
        <li>Does the ICD have a <b>strategic</b> location close to main highways and seaports or terminals?</li>
        <li>Is the ICD in a <b>business area</b> or a residential area?</li>
        <li>Is the site <b>efficient</b> in terms of shape and size?</li>
        <li>Are <b>all fees agreed</b> up front in a tariff or are their hidden fees?</li>
        <li>How <b>digital</b> is the ICD to take advantage of digital payments or automation?</li>
        <li>Does the ICD offer <b>24/7 operations</b> and access?</li>
        </ol>`,
        `  &nbsp;  `,
        `  &nbsp;   `,
        `<ol>
          <li><p>Ensure the ICD has a Depot licence <a href="https://peraturan.go.id/peraturan/view.html?id=11e7392403cd46029a3e313130373330" target="_blank">(SIUPDPK)</a> and is a registered company
Many ICD operators in Jakarta and Indonesia do not have the official government issued Depot Licence and this can cause companies who use such ICDs challenges and problems. Ensure that any facility you choose has the necessary <a href="https://peraturan.go.id/">permits</a>
</p></li>
          <li><p>Check the ICD is Quality compliant with regard to  <a href="https://www.iso.org/standards.html" target="_blank">ISO</a> and certifications Being ISO compliant, be it ISO 9001, 14001 and 45001, shows a commitment from an ICD that quality is a focus. ICDs that have these certifications are showing how important providing quality and reliability is to their customers. Also important is international certification from the <a href="https://www.iicl.org/" target="_blank">Institute of International Container Lessors (IICL)</a>
</p></li>
          <li>Does the ICD have adequate insurance?
<p>Make sure any ICD is covered by adequate insurance so that any problems are fully covered</p>
</li>
          <li>Is the Operations team experienced and qualified?
<p>Many newer ICDs are started without a focus on hiring experienced and qualified employees that have previous experience in the field. This can cause challenges for customers when problems arise that an inexperienced team may struggle to fix. Choose an ICD that has an experienced team and that trains its employees on a regular basis.</p>

          </li>
          <li>Does the ICD have a good location close to main highways and seaports or terminals?
<p>While this may sound obvious, it's hugely important for an ICD to have a strategic location that allows for easy access to highways, seaports and terminals. This will shorten the time taken to move cargo between customers and the ICD.</p>
</li>
          <li>Is the ICD in a business area or a residential area?
<p>Operating from a residential area may cause problems in terms of congestion or access. An ICD that is located in a business area will have fewer access problems and is likely to be closer to customer sites</p>

          </li>
          <li>Is the site efficient in terms of shape and size?
<p>While it may not seem obvious the shape and size of an ICD can impact productivity. Lower productivity can cause challenges for customers.</p>
</li>
          <li>Are all fees agreed up front in a tariff or are their hidden fees?
<p>While everyone likes a good deal, make sure that all fees are agreed up front or via a tariff and that there are no surprises later on. Working with an established ICD should help overcome any such problem.</p>
</li>
          <li>How digital is the ICD to take advantage of digital payments or automation?
<p>IT adoption and automation are making may areas of business easier, faster and more transparent, be it booking, operations, finance, or customer service. Make sure an ICD you are looking at is focused digitally so that you can both share the benefits.</p>
</li>
          <li>Does the ICD offer 24/7 operations and access?
          <p>If an ICD doesn't have the correct permits or is based in the wrong area getting access 24/7 may be a problem. Make sure that you are able to access your cargo when you need it.</p></li>
        </ol>`,
        `<img width="100%" src="./../../../assets/news/13thjunenews2.png"/>`,

        `&nbsp;`,
        `PT. Good Logistics Terminal <a href="https://glterminal.com/">(GL Terminal)</a> is a new logistics leader in Indonesia. Formed in 2020 we are unencumbered by legacy issues, which means we are able to embrace the latest technology and modern management principles to ensure we lead the industry in service and innovation.`,
        ,
        `GL Terminal’s focus is on ensuring the highest level of customer service and quality are available to ICD customers in Jakarta and the surrounding areas of Indonesia’s capital.`,
        `For more information on one of Jakarta and Indonesia’s leading and most advanced inland container depots please visit GL Terminal’s overview here.`,
        `Below is a table showing some of the key areas that ICDs face and how well placed GL Terminal is to serve its customers.`,
        `<img width="100%" src="./../../../assets/news/13thjunenews3.png"/>`,
      ],
    },
    {
      id: 'news-31-may-2021',
      newsImg: './../../../assets/news/glWhitelogo.png',
      mainImage: [
        './../../../assets/news/glt1.jpg',
        './../../../assets/news/glt2.jpg',
      ],
      newsHeading:
        'Experienced Management Team Launch GL Terminal, An Innovative and Digitally Focused Inland Container Depot to Serve Jakarta and Surrounding Areas',
      newsContent:
        'Aim to bring world-class facility to help global shipping lines, large shippers and freight forwarders in Indonesia',
      date: '31st May, 2021',
      desc: [
        `Aim to bring world-class facility to help global shipping lines, large shippers and freight forwarders in Indonesia`,
        `Jakarta, Indonesia, June 1, 2021: In response to increasing demand in Indonesia for world-class Inland Container Depot (ICD) facilities, a team of Indonesian-based entrepreneurs launched PT. Good Logistics (GL) Terminal in late 2020. The facility is based in Jakarta, only 10km from Jakarta port, and has an annual throughput capacity of 180,000 TEUs. In addition to state-of-the-art material handling equipment (MHE),<a href="https://www.glterminal.com/"> GL Terminal</a> utilizes technology and digital innovation to make the user experience easier and more efficient, while ensuring greater visibility into the process and higher quality for all users.`,
        `The experienced team of entrepreneurs and innovators is led by Paul Good, who has experience in owning and managing logistics, exporting, manufacturing, and FMCG businesses. After many years of doing business in Indonesia they realized that there was a need and a demand for ICD facilities in Indonesia that utilized best-in-class management and quality and would help overcome some of the traditional challenges in the local market. “We believe that there is a demand for world-class facilities in Indonesia and launched GL Terminal in response. Our aim is to be leading provider in this market and to provide a product that includes service quality, compliance, health, safety, and environmental sustainability,” said Paul Good. With a focus on enabling technology and innovation, he also stated that “GL Terminal was designed and built to utilize technology and digital innovations to increase productivity, quality, and service levels, and to provide levels of service that are standard in other countries.”`,
        `GL Terminal benefits from a strategic location that is adjacent to the Jakarta Outer Ring Road TOL and less than 10kms from Tg Priok Jakarta Port. This location also provides direct access to arterial highways allowing for a rapid and efficient response to customers' import/export and distribution requirements. The GL Terminal is situated on a three hectare concreted yard that has available land for expansion and includes a range of MHE for laden and empty container stacking. On-site employees receive internal and external training programs and comply with government regulations on manpower and hold international certification from the Institute of International Container Lessors (IICL). With a capacity of close to 2,000 TEUs per day, separate import and export gates allow for efficient access, while a range of on-site services in addition to storage include surveys, maintenance, cleaning, 24/7 access and operations, and full security.`,
        `In addition to an excellent location and knowledgeable management team that is led by Nanang Faruq, a 20-year industry veteran, GL Terminal is partnering with logistics technology disruptors such as Logol <a href="https://logol.co.id/" target="_blank">(www.logol.co.id)</a> who offer seamless connectivity and payment facilities with the ports, including electronic gate passes and electronic payment of gate in/gate out fees that will speed up activities. Technological deployments will include a robust in-house Container Depot Management System, QR Code implementation for loading and un-loading, and real-time EDI reporting based on customer request. Developments in the pipeline include a real time web dashboard and barrier gate automation.`,
        `While GL Terminal is new to the market its founders and management team are not. This brings an ability to start with a clear vision and a clean slate to disrupt traditional practices in the local market, with a view to driving up productivity, innovation, and compliance levels that are expected in other markets and are in demand from shipping companies, shippers, and forwarders.`,
        '-----------------------------------------------------------------',
        `About GL Terminal`,
        `PT. Good Logistics Terminal (GL Terminal) is a new logistics leader in Indonesia. Formed in 2020 we are unencumbered by legacy issues, which means we are able to embrace the latest technology and modern management principles to ensure we lead the industry in service and innovation.`,
        `GL Terminal has been developed by a team led by Paul Good. Paul is an experienced logistics professional and entrepreneur, with a career that has included working in Australia, Indonesia, Hong Kong, Philippines, India, and Singapore. He has worked on both the operational and general management sides of the logistics business, and his experience includes a multitude of roles at Kuehne and Nagel and Agility.`,
        `Our experienced team possess combined industry domain knowledge of over thirty years and are certified to International standards in relation to quality, health, and safety and environmental practices. Our strategic location adjacent to the Jakarta Outer Ring Road TOL places us less than 10kms from Tg Priok Jakarta Port. This location also provides direct access to arterial highways allowing us to respond rapidly and efficiently to our customers import / export and distribution requirements.`,
        `For more information please contact Paul Good at +62 812-8828-2221 or Paul@glterminal.com or visit our website at <a href="https://www.glterminal.com/"> https://www.glterminal.com/</a>`,
      ],
    },
    {
      id: 'news-30-may-2021',
      newsImg: './../../../assets/news/glWhitelogo.png',
      mainImage: [
        './../../../assets/news/glt2.jpg',
        `./../../../assets/news/glt3.png`,
      ],
      newsHeading: 'An Overview of Inland Container Depots',
      newsContent:
        'As defined by Drip/C an inland container depot (ICD) is an open yard for container handling, storage, cleaning and maintenance/repair services that is usually located at an inland point away from a sea/ocean port.',
      date: '30th May, 2021',
      desc: [
        `As defined by <a href="https://www.dripcapital.com/resources/blog/what-is-inland-container-depot-icd">Drip/C</a> an inland container depot (ICD) is an open yard for container handling, storage, cleaning and maintenance/repair services that is usually located at an inland point away from a sea/ocean port. Proximity to a port is important due to access requirements. ICDs help shipping companies, freight forwarders, shippers, importers and exporters to handle their cargo and shipments near their port of import or export. `,
        `Containers are moved from the ship to the ICD and vice versa using rail and road networks.An ICD is recognised by government and port authorities as an extension of the sea port activities. Specialized skilled teams and international <a href="https://www.iicl.org/">IICL<a/> certification are required to handle day-to-day operations, including compliance with all local regulations.`,
        `Containers are still the most economical logistics mode for global trade. The number of containers keeps increasing every year, as noted by <a href="https://alphaliner.axsmarine.com/PublicTop100/">Alpha Liner</a>. The top 100 worldwide operators had more than 24 million TEUs as of Q1 2021. Any export and import activities will nearly always involve ICDs, making ICDs an inseparable part of global trade and international supply chains.`,
        `Why are Inland Container Depots Important?`,
        `As  a container handling and storage facility located in close proximity to a port, ICDs are important as they allow shippers to store, move, repair, or maintain containers before or after utilizing them via a port. Instead of causing more congestion at ports ICDs allow for efficient utilization of space, equipment and resources. `,
        'In addition to the services provided, freight forwarders, exporters, importers and other related parties also provide services to ICD users. This helps relieve pressure on ports where storage space is limited and ensures that there is time, resources and equipment to hand.',
        `In the current era of digitalization, the ICD is required to participate in and present the concept of digitizing logistics, an integrated service with other stakeholders in the global supply chain. It will make running business processes easier, faster, accurate and secure data, transparent, accountable and efficient.`,
        `Key Functions of an Inland Container Depot`,
        `Inland Container Depots carry out the following functions:`,
        `<ol>
        <li>ICDs are physical facilities that store containers temporarily (either laden or empty) before they are moved to a port and loaded onto a vessel/ships. Or in the opposite way, after containers are unloaded from a vessel then delivered to an ICD in empty or laden condition before/after unloading the cargos to the warehouse or factory. </li>
        <li>Exporters can also place their cargo inside containers at an ICD or unstrip containers before further transportation occurs.</li>
        <li>ICDs also act as a center for cleaning, servicing and repairs for containers and other related equipment.</li>
        </ol>`,
        `In summary the following functions are carried out:`,
        `<ul>
        <li>Receipt and dispatch/delivery of cargo</li>
        <li>Stuffing and un-stripping of containers</li>
        <li>Transit operations by rail/road to and from serving ports.</li>
        <li>Consolidation and desegregation of LCL cargo.</li>
        <li>Temporary storage of cargo and empty or laden containers</li>
        <li>Reworking of containers</li>
        <li>Maintenance, cleaning and repair of container units. </li>
        </ul>`,
        `Benefits of an Inland Container Depot`,
        `<ul>
        <li>An ICD’s key function is to identify and ensure all containers are in suitable condition to reuse for export/import activities or logistics services</li>
        <li>ICDs reduce the overall movement of empty containers by providing consolidation facilities for <a href="https://www.tradefinanceglobal.com/freight-forwarding/lcl-less-than-container-load/">Less than Container Load</a> (LCL) shipments away from ports.</li>
        <li>Due to their location ICDs can help in reducing congestion at a port and its surrounding areas. Any delays in the import or export of containers will add only costs to the supply chain and make a port less attractive for use.</li>
        <li>A full range of services can be provided at ICDs such as cleaning, repairs and maintenance, garment on hangers (GoH), reefer inspections etc</li>
        <li>ICD’s value added services (VAS) could be carried out on behalf of Shipping Lines to collect demurrage and detention charges from Shippers/Consignees.</li>
        </ul>`,
        `Challenges of an Inland Container Depot`,
        `<ul>
        <li>To be effective an ICD needs to be in close proximity to main ports and roads and/or railways. Without access the benefits of ICDs will be lowered.</li>
        <li>Government regulations and requirements need to be followed and ICDs must ensure they are fully compliant with all local rules and regulations or users will face challenges.</li>
        <li>ICDs need to ensure they have access to the necessary equipment for effective operations.</li>
        <li>A skilled, experienced and effective workforce is needed to ensure operations run smoothly.</li>
        </ul>`,
        `For more information about the latest entrant to the Indonesian ICD market please visit GL Terminal at <a href="https://www.giterminal.com/">glterminal.com</a>. GL Terminal was setup in response to increasing demand in Indonesia for world-class ICD facilities by two Indonesian-based entrepreneurs in late 2020. The facility is based in Jakarta, only 10km from Jakarta port, and has an annual throughput capacity of 180,000 TEUs. `,
        `In addition to state-of-the-art material handling equipment (MHE), GL Terminal utilizes technology and digital innovation to make the user experience easier and more efficient, while ensuring greater visibility into the process and higher quality for all users. `,
        `To view GL Terminal’s services please click <a href="https://glterminal.com/service">here.</a>`,
      ],
    },
  ],
};
