<section class="main-page">
  <div class="topnavbar">
    <web-site-top-navbar></web-site-top-navbar>
  </div>
  <div class="carousel-section">
    <web-site-carousel-main></web-site-carousel-main>
  </div>
  <div>
    <div class="facilities">Introducing GL Terminal</div>
    <div class="facilities-total">
      <div class="facilities-box" *ngFor="let facilitie of facilities">
        <div class="facilities-images">
          <img [src]="facilitie.image" />
          <div class="hover-text">
            <div class="text">{{ facilitie.hoverText }}</div>
          </div>
        </div>
        <div class="facilities-text">{{ facilitie.text | titlecase }}</div>
      </div>
    </div>
    <div class="totalPacking">
      <div class="Packingimage">
        <img src="./../../../../assets/mainpage/map.svg" />
      </div>
      <div class="Packingstorage">
        <div class="Packingstorage-text">Strategic Location</div>
        <div class="Packingstorage-subtext">
          GL Terminal benefits from a strategic location that is adjacent to the
          Jakarta Outer Ring Road TOL and less than 10 kms from Tg Priok Jakarta
          Port. Direct access to arterial highways.
        </div>
        <div class="totaltransport-image" align="center">
          <div class="transport-image">
            <div class="large-site">
              <div class="content-box-strat">
                <div class="img-strat">
                  <img
                    src="./../../../../assets/mainpage/large-site-icon.svg"
                    alt="large-site"
                    class="icon-strat"
                  />
                </div>
                <div class="heading-strat">Large Site</div>
                <div class="strat-text">
                  Situated on a three hectare concreted yard that has available
                  land for expansion and includes a range of MHE for laden and
                  empty container stacking.
                </div>
              </div>
            </div>
          </div>
          <div class="transport-image">
            <div class="skilled-employee">
              <div class="content-box-strat">
                <div class="img-strat">
                  <img
                    src="./../../../../assets/mainpage/skilled-employee-icon.svg"
                    alt="large-site"
                    class="icon-strat"
                  />
                </div>
                <div class="heading-strat">Skilled Employees</div>
                <div class="strat-text">
                  Employees receive internal and external training programs and
                  comply with government regulations on manpower and hold
                  international certification from the Institute of
                  International Container Lessors (IICL).
                </div>
              </div>
            </div>
          </div>
          <div class="transport-image">
            <div class="capacity-service">
              <div class="content-box-strat">
                <div class="img-strat">
                  <img
                    src="./../../../../assets/mainpage/capacit-service-icon.svg"
                    alt="large-site"
                    class="icon-strat"
                  />
                </div>
                <div class="heading-strat">Capacity & Services</div>
                <div class="strat-text">
                  With a capacity of close to 2,000 TEUs per day, separate
                  import and export gates allow for efficient access, while a
                  range of on-site services in addition to storage include
                  surveys, maintenance, cleaning, 24/7 access and operations,
                  and full security.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="second">
      <div class="tech-side">
        <div class="box-side">
          <div class="technology">About GL Terminal</div>
          <div class="para1">
            PT. Good Logistics Terminal (GL Terminal) is a new logistics leader
            in Indonesia. Formed in June 2020 we are unencumbered by legacy
            issues, which means we are able to embrace the latest technology and
            modern management principals to ensure we lead the industry in
            service and innovationand modern management principals to ensure we
            lead the industry in service and innovationand modern management
            principals to ensure we lead the industry in service and innovation.

            <div class="btn">
              <button class="submit-btn">KNOW MORE</button>
            </div>
          </div>
        </div>
      </div>
      <div class="all-terminalimage">
        <img src="./../../../../assets/allteminal.png" />
      </div>
    </div> -->

    <div class="Servicesoffer">
      <div class="Services-heading">
        {{ "Services and capabilities" | titlecase }}
      </div>
      <div class="all-Servicesoffer">
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/laden-storage.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "Laden Container Storage" | titlecase }}
            </div>
            <div class="onestop-subtext">
              Concrete square yard, fully secure
            </div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/empty-conatiner.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "Empty Container Storage" | titlecase }}
            </div>
            <div class="onestop-subtext">
              Concrete square yard, fully secure
            </div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/maintainance.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "Maintenance and Repair" | titlecase }}
            </div>
            <div class="onestop-subtext">
              Perform Repair based on IICL standard, Container Upgrade,
              Refurbishment
            </div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/side-loader.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "Side Loader and Reach Stackers" | titlecase }}
            </div>
            <div class="onestop-subtext">
              Own equipment, scalable based on volume
            </div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/customer-service.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "Customer service" | titlecase }}
            </div>
            <div class="onestop-subtext">
              Check and Validation, Administration, Repair Charge Collection,
              Demurrage/Detention Collection
            </div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/24-7.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "24/7 Operations" | titlecase }}
            </div>
            <div class="onestop-subtext">Full flexibility, dedicated area</div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/yard-management.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "Yard Management System" | titlecase }}
            </div>
            <div class="onestop-subtext">
              FIFO Concept, Block Arrangement, Traffic Management
            </div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/quality-control.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "Quality Control" | titlecase }}
            </div>
            <div class="onestop-subtext">
              Quality Repair based on IICL standard, Recheck whole container
              condition
            </div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/garment-hanger.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "Garment oN Hanger " | titlecase }}(GoH)
            </div>
            <div class="onestop-subtext">
              Fabrication and or Installation only, Single/Double Bar,
              Standard/Heavy Duty based on Customer request
            </div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/reefer-service.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "Reefer Services" | titlecase }}
            </div>
            <div class="onestop-subtext">
              Pre-Trip Inspection (PTI), Pre-Cooling, Engine Repair, Trouble
              Shooting and Monitoring
            </div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/one-stop.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "ONE STOP GATE IN" | titlecase }}
            </div>
            <div class="onestop-subtext">
              Survey, Washing and Estimate of Repair (EOR)
            </div>
          </div>
        </div>
        <div class="Servicesoffer-image">
          <div class="onestop-image">
            <img
              src="./../../../../assets/mainpage/service-offer/wide-entrance.svg"
              class="gate-img"
            />
          </div>
          <div class="onestop-text">
            <div class="onestop-heading">
              {{ "Wide Entrance" | titlecase }}
            </div>
            <div class="onestop-subtext">Separate entrance and exit</div>
          </div>
        </div>
      </div>
      <div class="btn">
        <button class="submit-btn" (click)="changeRoute('/service')">
          KNOW MORE
        </button>
      </div>
    </div>

    <div class="latestNews">
      <div class="latestNew-heading">{{ "Why GL Terminal" }}</div>
      <div class="latestNew-box">
        <div class="all-latestNews" *ngFor="let latest of whyList">
          <div class="latestNewsimages">
            <img [src]="latest.image" class="why-img" />
          </div>
          <div class="details-section-why">
            <div class="latestNews-heading">
              {{ latest.heading | titlecase }}
            </div>
            <div class="latestNews-subtext">
              <ul>
                <li *ngFor="let why of latest.subText">
                  {{ why }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="second last-sec">
      <div class="s-img">
        <img
          src="./../../../../assets/mainpage/civilengineer.png"
          alt=""
          width="660px"
        />
      </div>
      <div class="tech">
        <div class="box">
          <div class="technology">{{ "WHY CHOOSE US?" | titlecase }}</div>
          <div class="para1">
            As a new logistics leader in Indonesia, GL Terminal has an
            experienced and knowledgeable team that understands customers’
            requirements and is proactive in delivering the solutions and
            capabilities that are needed in today’s ever changing marketplace.
            Contact us now to find out how we are helping companies in Jakarta
            and beyond solve their logistics challenges.
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="second">
      <div class="tech-side">
        <div class="box-side">
          <div class="technology">
            <img src="./../../../../assets/mainpage/invertedCommas.png" />
          </div>
          <div class="para1">
            All data and information about a consignment are visible at a glance
            in the DL Terminal system with the entire communication process.
            Today, I simply click through the individual bookings for all
            shipments of goods and thus, know—at any time—exactly which
            consignments arrive in which container and when. This is much
            easier, faster, and, above all, more transparent for us than before
          </div>
          <div class="total-image">
            <div class="image-part">
              <img
                src="./../../../../assets/mainpage/ladyImage.png"
                width="120%"
              />
            </div>
            <div class="image-parttext">
              <div class="image-partheading">AMY ADAMS</div>
              <div class="image-parttitle">CEO Global company Inc, France</div>
            </div>
          </div>
        </div>
      </div>

      <div class="all-terminalimage">
        <img src="./../../../../assets/mainpage/successStories.png" />
       
        <div class="success-sto">SUCCEESS STORIES</div>
      </div>
    </div> -->
  </div>

  <div class="foot">
    <web-site-footer></web-site-footer>
  </div>
</section>
