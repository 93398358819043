<div class="foot">
  <div class="bg-download-tax">
    <div class="download-tax-footer">
      <div class="download-tax-sec">
        <div class="head">Click here to download the tax invoice </div>
        <!-- <div class="fsec-sub">
          GL Terminal, an innovative and digitally focused Inland Container
          Depot serving Jakarta and surrounding areas.
        </div> -->
      </div>
      <button class="d-btn" (click)="routingPath('download-tax')">
        Download
      </button>
    </div>
  </div>
  <div class="bg-semifooter">
    <div class="semi-footer">
      <div class="fsec">
        <div class="head">A new world-class ICD for Jakarta</div>
        <div class="fsec-sub">
          GL Terminal, an innovative and digitally focused Inland Container
          Depot serving Jakarta and surrounding areas.
        </div>
      </div>
      <button class="c-btn" (click)="routingPath('contact-us')">
        Contact Us
      </button>
    </div>
  </div>

  <div class="bgmain-footer">
    <div class="footer">
      <div class="first-sec">
        <img
          class="fimg"
          src="./../../../../assets/glWhitelogo.svg"
          width="300px"
        />
        <div class="total-text">
          <div class="text">
            PT. Good Logistics Terminal (GL Terminal) is a new logistics leader
            in Indonesia. Formed in June 2020 we are unencumbered by legacy
            issues, which means we are able to embrace the latest technology and
            modern management principles to ensure we lead the industry in
            service and innovation.
          </div>
        </div>
      </div>

      <div class="second-sec">
        <div class="info">{{ "CONTACT INFO" | titlecase }}</div>
        <div class="add">
          Jl. Raya Cakung Cilincing Pal II, Cilincing Jakarta Utara 14140
          Indonesia
        </div>
        <div class="mail">Email : info@glterminal.com</div>
        <div class="mob">Phone : +62 21 2240 6574</div>
      </div>

      -
      <div class="third-sec">
        <div class="link">Useful links</div>
        <div class="new">
          <div class="service" (click)="routingPath('about-us')">About Us</div>
        </div>
        <div class="new">
          <div class="service" (click)="routingPath('service')">Services</div>
        </div>
        <div class="new">
          <div class="service" (click)="routingPath('technology-innovation')">
            Technology & Innovation
          </div>
        </div>
        <div class="new">
          <div class="service" (click)="routingPath('qhse-certificate')">
            QHSE & Certification
          </div>
        </div>
        <!-- <div class="new">
          <div class="service">Online Services</div>
        </div> -->
      </div>
      <div class="last-conent">
        <div class="last-conent-text" (click)="routingPath('news')">News</div>
        <!-- <div class="last-conent-text">faq</div> -->
        <div class="last-conent-text" (click)="routingPath('contact-us')">
          Contact Us
        </div>
        <!-- <div class="last-conent-text" (click)="routingPath('contact-us')">
          Get A Quote
        </div> -->
      </div>
    </div>
    <div class="bgmain-footer">
      <div class="total-icons">
        <div class="icons" *ngFor="let images of social">
          <div class="social-links">
            <img [src]="images.image" width="20px" height="20px" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fourth-sec">
    <div class="sub-text">
      GL TERMINAL © 2021 All Rights Reserved. Terms of Use and Privacy Policy
    </div>
    <div class="creator-link sub-text">
      <a
        href="https://letsmobility.com"
        target="_blank"
        rel="noopener noreferrer"
        ><b>Website by Letsmobility Software Solutions (P) Ltd</b>
      </a>
    </div>
  </div>
</div>
