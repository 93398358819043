<section class="qhse-section-bg">
  <div class="topnavbar">
    <web-site-top-navbar></web-site-top-navbar>
  </div>
  <div class="f-sec">
    <div class="ino">
      <div class="heading">
        QHSE & <br />
        Certification
      </div>
      <div class="para">
        As part of our commitment to our employees, our customers, and our
        partners, GL Terminal has a strategic focus on Quality, Health, Safety,
        and Environment Management systems that is managed via our commitment to
        various management systems and international certifications.
      </div>
    </div>
    <div class="i-img">
      <img
        src="./../../../assets/yellowhelmet.svg"
        alt="qhse-certificate"
        class="qhse-certificates"
      />
    </div>
  </div>
  <div>
    <div class="main">
      <div class="second-sec">
        <div class="aware">
          <div class="cert">QHSE{{ " & CERTIFICATION" | titlecase }}</div>
          <p class="para1">
            GL Terminal has an Official Container Depot Permit from Indonesian
            Government
          </p>
          <p class="para1">Integrated Management System and Insurance</p>
          <!-- <p class="para1">INTEGRATED MANAGEMENT SYSTEM</p> -->
          <div class="para3">
            <p class="cert">{{ "Integrated Management System" | titlecase }}</p>
            <ul class="bullet-points">
              <li>ISO 9001: 2015 Quality Management System</li>
              <li>ISO 45001: 2018 Occupational Health</li>
              <li>ISO 45001: 2018 Occupational Safety</li>
              <li>ISO 14001: 2015 Environmental Management System</li>
            </ul>
          </div>
        </div>
        <div class="right-sec">
          <div class="e-img">
            <img src="./../../../assets/quality.png" width="150px" />
            <img src="./../../../assets/health.png" width="150px" />
          </div>
          <div class="e-img">
            <img src="./../../../assets/safety.png" width="150px" />
            <img src="./../../../assets/environment.png" width="150px" />
          </div>
        </div>
      </div>

      <div class="third-sec">
        <div class="t-img">
          <img class="sealed-img" src="./../../../assets/sealImage.png" />
        </div>
        <div class="bullet">
          <p class="cert1">
            {{ "INTERNATIONAL AND COMPETENCY CERTIFICATION" | titlecase }}
          </p>
          <ul class="para1">
            <li>IICL</li>
            <li>Reefer</li>
            <li>Welding</li>
            <li>IMS</li>
            <li>Operator License</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="foot">
    <web-site-footer></web-site-footer>
  </div>
</section>
