import { DomSanitizer } from '@angular/platform-browser';
import { NEWS } from './../news-list/news-constants';
import {
  AfterViewInit,
  Component,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare const $: any;

@Component({
  selector: 'web-site-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: [
    './news-details.component.scss',
    'mq-news-details.component.scss',
  ],
})
export class NewsDetailsComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  newsList = NEWS.listofNews;
  suggestedList: any[] = this.newsList;
  mainNews: any;
  ngOnInit(): void {
    // this.suggestedList.length = 2;
    this.route.params.subscribe((data) => {
      this.mainNews = this.newsList.filter((mn: any) => {
        if (mn.id === data.id) {
          return mn;
        }
      })[0];
    });
  }

  pageChange(id: any) {
    this.router.navigate(['/glterminal-press-release', id]);
    window.scrollTo(0, 0);
  }
}
